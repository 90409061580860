import {
  makeRefinement,
  RefinementError,
  RefinementFunctionType,
  refineNumber,
  refineString,
  refineStringLiteral,
} from '@normed/refinements';
import { Nested } from './utils';

export const isAPIItem = (v: APIItem | Nested<APIItem>): v is APIItem =>
  v && typeof v === 'object' && v['type'] === 'item';

export const refineAPIItem = makeRefinement({
  type: refineStringLiteral('item'),
  name: refineString,
  path: refineString,
  observe: makeRefinement({
    name: refineString,
    failures: refineNumber,
    requests: refineNumber,
    activeRequests: refineNumber,
    cache: (_p: string[], v: unknown): RefinementError | JSONishMember =>
      v as JSONishMember,
  }),
});
export type APIItem = RefinementFunctionType<typeof refineAPIItem>;
