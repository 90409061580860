import { useEffect, useRef, useState } from 'react';

/**
 * Trigger re-renders when the dimensions of an element change,
 * optionally returning an initial set of dimensions.
 */
export function useWatchDimensions<T extends HTMLElement>(
  initialDimensions: {
    width: number;
    height: number;
  } = { width: 0, height: 0 },
): [React.RefObject<T>, { width: number; height: number }] {
  const ref = useRef<T>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [dimensions, setDimensions] = useState(initialDimensions);

  useEffect(() => {
    function updateDimensions() {
      if (ref.current) {
        const el = ref.current;
        const { width, height } = el.getBoundingClientRect();
        if (dimensions.width !== width || dimensions.height !== height) {
          setDimensions({ width, height });
        }
      }
    }

    if (timeout.current === null) {
      function next() {
        updateDimensions();
        timeout.current = setTimeout(next, 2000);
      }
      timeout.current = setTimeout(next, 0);
    }

    return () => {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [dimensions]);

  return [ref, dimensions];
}
