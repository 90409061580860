import React, { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Copy, Check } from 'lucide-react';

export const CopyButton: React.FC<{ value: string }> = ({ value }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            onClick={copyToClipboard}
            className="p-2 hover:bg-broccoli-neutral-100 rounded-full transition-colors"
          >
            {isCopied ? (
              <Check className="h-4 w-4 text-green-500" />
            ) : (
              <Copy className="h-4 w-4" />
            )}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="bg-gray-800 text-white px-2 py-1 rounded text-sm"
            sideOffset={5}
          >
            {isCopied ? 'Copied!' : 'Copy to clipboard'}
            <Tooltip.Arrow className="fill-gray-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
