import React, { useEffect, useMemo, useReducer, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DateTime, Interval } from 'luxon';
import { Period, getLengthDays, periods } from '../utils';
import { absurd } from '@numbereight/utils';
import { tz } from 'moment-timezone';

const defaultTimeZone = 'utc';
const defaultPeriod = '2 months';

export function DateRangeSelector_Reducer(
  state: DateRangeSelector_State,
  action: DateRangeSelector_Action,
) {
  switch (action.type) {
    case 'set':
      // Validation

      const mergedState = {
        ...state,
        ...action.state,
      };

      // calculate actual output values
      switch (mergedState.mode) {
        case 'custom range':
          mergedState.from = mergedState.range[0]
            ? DateTime.fromJSDate(mergedState.range[0]).setZone(
                mergedState.timezone,
                { keepLocalTime: true },
              )
            : null;
          mergedState.to = mergedState.range[1]
            ? DateTime.fromJSDate(mergedState.range[1]).setZone(
                mergedState.timezone,
                { keepLocalTime: true },
              )
            : null;
          break;
        case 'period':
          mergedState.to = mergedState.toDate
            ? DateTime.fromJSDate(mergedState.toDate).setZone(
                mergedState.timezone,
                {
                  keepLocalTime: true,
                },
              )
            : null;
          mergedState.from =
            mergedState.period && mergedState.to
              ? mergedState.to.minus({
                  days: getLengthDays(mergedState.period),
                })
              : null;
          break;
        default:
          absurd(mergedState.mode);
      }

      // Call on change if something changed (ok in reducer?)
      if (mergedState.from != state.from || mergedState.to != state.to) {
        state.onChange?.({ from: mergedState.from, to: mergedState.to });
      }

      return mergedState;
    default:
      absurd(action.type);
  }
}

export type DateRangeSelector_DefaultValue =
  | { to?: DateTime | null; from?: DateTime | null; mode: 'custom range' }
  | {
      // Set the default period based on a to and from or a to and period
      to?: DateTime | null;
      from?: DateTime | null;
      period?: Period;
      mode: 'period';
    };
export type DateRangeSelector_Value = {
  from: DateTime | null;
  to: DateTime | null;
};
export type DateRangeSelector_Action = {
  type: 'set';
  state: Partial<DateRangeSelector_State>;
};
export type DateRangeSelector_State = {
  // Internal states
  period: Period | null;
  mode: 'period' | 'custom range';
  toDate: null | Date;
  range: [null | Date, null | Date];
  timezone: string;

  // Output states
  from: DateTime | null;
  to: DateTime | null;

  // Input function
  onChange?: (v: DateRangeSelector_Value) => void;
};

export const dateRangeModes = ['custom range', 'period'] as const;

export function DateRangeSelector({
  onChange,
  defaultValue,
}: {
  onChange?: (v: DateRangeSelector_Value) => void;
  defaultValue?: DateRangeSelector_DefaultValue;
}) {
  const [state, dispatch] = useReducer(
    DateRangeSelector_Reducer,
    resolveState(defaultValue, defaultTimeZone, onChange),
  );
  const [maxDate, setMaxDate] = useState<Date>(
    DateTime.utc().startOf('day').toJSDate(),
  );

  // In case it tips over midnight, we shouldn't have to refresh the page!
  useEffect(() => {
    const msUntilTomorrow = Math.abs(
      DateTime.fromJSDate(maxDate).plus({ day: 1 }).diffNow().toMillis(),
    );
    const timeout = setTimeout(() => {
      setMaxDate(DateTime.utc().startOf('day').toJSDate());
    }, msUntilTomorrow + 1);
    return () => clearTimeout(timeout);
  }, [maxDate]);

  const formatPeriodLabel = (period: Period | null) =>
    period ? `${period} (${getLengthDays(period)}d)` : null;
  const periodOptions = useMemo(() => {
    return periods.map((period) => ({
      label: formatPeriodLabel(period),
      value: period,
    }));
  }, []);

  const modeOptions = useMemo(() => {
    return dateRangeModes.map((mode) => ({
      label: mode,
      value: mode,
    }));
  }, []);

  const timezoneOptions = useMemo(() => {
    // Extract all valid timezones
    const luxonValidTimezones = tz
      .names()
      .filter((tz) => tz != 'utc' && DateTime.local().setZone(tz).isValid);

    return [
      // Include a local time for reference.
      { label: 'Local', value: DateTime.local().zoneName },
      // Put UTC second.
      { label: 'UTC', value: 'utc' },
      ...luxonValidTimezones.map((z) => {
        return { label: z, value: z };
      }),
    ];
  }, []);

  return (
    <>
      <div
        style={{
          display: 'inline-block',
          minWidth: '13em',
          textAlign: 'center',
        }}
      >
        <Select
          value={{ label: state.mode, value: state.mode }}
          onChange={(mode) => {
            dispatch({ type: 'set', state: { mode: mode?.value ?? 'period' } });
          }}
          options={modeOptions}
        />
      </div>
      {state.mode === 'period' ? (
        <>
          <span>&nbsp;of&nbsp;</span>
          <div
            style={{
              display: 'inline-block',
              minWidth: '10em',
              textAlign: 'center',
            }}
          >
            <Select
              value={{
                label: formatPeriodLabel(state.period),
                value: state.period,
              }}
              onChange={(p) =>
                dispatch({ type: 'set', state: { period: p?.value ?? null } })
              }
              options={periodOptions}
            />
          </div>
          <span>&nbsp;until&nbsp;</span>
          <DatePicker
            maxDate={maxDate}
            onChange={(x) =>
              dispatch({
                type: 'set',
                state: { toDate: Array.isArray(x) ? null : x ?? null },
              })
            }
            value={state.toDate}
          />
          <span>&nbsp;in timezone&nbsp;</span>
          <div
            style={{
              display: 'inline-block',
              minWidth: '13em',
              textAlign: 'center',
            }}
          >
            <Select
              value={{ label: state.timezone, value: state.timezone }}
              onChange={(timezone) => {
                dispatch({
                  type: 'set',
                  state: { timezone: timezone?.value ?? defaultTimeZone },
                });
              }}
              options={timezoneOptions}
            />
          </div>
        </>
      ) : (
        <>
          &nbsp;
          <DateRangePicker
            maxDate={maxDate}
            value={state.range}
            onChange={(r) =>
              dispatch({
                type: 'set',
                state: { range: Array.isArray(r) ? r : [null, r ?? null] },
              })
            }
          />
          <span>&nbsp;in timezone&nbsp;</span>
          <div
            style={{
              display: 'inline-block',
              minWidth: '10em',
              textAlign: 'center',
            }}
          >
            <Select
              value={{ label: state.timezone, value: state.timezone }}
              onChange={(timezone) => {
                dispatch({
                  type: 'set',
                  state: { timezone: timezone?.value ?? defaultTimeZone },
                });
              }}
              options={timezoneOptions}
            />
          </div>
        </>
      )}
    </>
  );
}

export function resolveDefaultValue(
  defaultValue: DateRangeSelector_DefaultValue | undefined,
): DateRangeSelector_Value {
  const state = resolveState(defaultValue, defaultTimeZone, undefined);
  return {
    from: state.from,
    to: state.to,
  };
}

function resolveState(
  defaultValue: DateRangeSelector_DefaultValue | undefined,
  timezone: string,
  onChange: ((v: DateRangeSelector_Value) => void) | undefined,
): DateRangeSelector_State {
  const to = defaultValue?.to?.startOf('day') ?? null;
  const from = defaultValue?.from?.startOf('day') ?? null;
  const period =
    defaultValue?.mode === 'period' ? resolvePeriod(defaultValue) : null;
  return defaultValue
    ? defaultValue.mode === 'period'
      ? {
          period,
          mode: 'period',
          toDate: to?.toJSDate() ?? null,
          range: [null, null],
          from:
            to?.minus({
              days: getLengthDays(period ?? defaultPeriod),
            }) ?? null,
          to,
          timezone,
          onChange,
        }
      : {
          period: defaultPeriod,
          mode: 'custom range',
          toDate: to?.toJSDate() ?? null,
          range: [from?.toJSDate() ?? null, to?.toJSDate() ?? null],
          from,
          to,
          timezone,
          onChange,
        }
    : {
        period: defaultPeriod,
        mode: 'period',
        toDate: null,
        range: [null, null],
        from: null,
        to: null,
        timezone,
        onChange,
      };
}

function resolvePeriod(
  values: NonNullable<DateRangeSelector_DefaultValue>,
): Period {
  if (values.mode !== 'period') {
    return defaultPeriod;
  }

  // A provided period always takes priority.
  if (values.period) {
    return values.period;
  }

  if (values.to && values.from) {
    const dateDiff = Interval.fromDateTimes(values.from, values.to).length(
      'days',
    );
    if (dateDiff <= 1) {
      return '1 day';
    } else if (dateDiff <= 7) {
      return '1 week';
    } else if (dateDiff <= 30) {
      return '1 month';
    } else if (dateDiff <= 60) {
      return '2 months';
    }
  }

  // Always return a value.
  return defaultPeriod;
}
