import { Query } from '@numbereight/udatabase';
import { DateTime } from 'luxon';
import { Period, getLengthDays } from '.';

export function periodLimits(
  q: Query,
  expression: string,
  period: Period | Period[],
  endDate: DateTime | DateTime[],
): string {
  const periods = Array.isArray(period) ? period : [period];
  const endDates = Array.isArray(endDate) ? endDate : [endDate];

  const maxLengthDays = Math.max(...periods.map((p) => getLengthDays(p)));

  const maxEndDate = DateTime.max(...endDates);
  const minEndDate = DateTime.min(...endDates);

  const start = minEndDate.minus({ days: maxLengthDays }).toISO();
  const end = maxEndDate.toISO();

  return `${expression} BETWEEN TIMESTAMP(${q.value(
    start,
  )}) AND TIMESTAMP(${q.value(end)})`;
}

export function periodsAsSql(
  q: Query,
  period: Period | Period[],
  endDate: DateTime | DateTime[],
): string {
  const periods = Array.isArray(period) ? period : [period];
  const endDates = Array.isArray(endDate) ? endDate : [endDate];

  const allPeriods = periods.flatMap((p) =>
    endDates.map((endDate) => ({
      period: p,
      end: endDate.toISODate(),
    })),
  );

  return `
    -- sqlHelper.periodsAsSql
    SELECT
      TIMESTAMP_TRUNC(TIMESTAMP(p.end), DAY) - ((INTERVAL 1 DAY) * p.length) AS date_from,
      TIMESTAMP_TRUNC(TIMESTAMP(p.end), DAY) AS date_to,
      period,
      length
    FROM ${q.help.values(
      q,
      'p',
      allPeriods.map(({ period, end }) => ({
        period,
        end,
        length: getLengthDays(period),
      })),
    )}
  `;
}
