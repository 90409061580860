import { get } from 'lodash';

type FileType = 'csv' | 'markdown';

/**
 * Generates a downloadable file from table data
 * @param columns - Array of column definitions with id and header
 * @param rows - Array of data rows
 * @param mode - Export format ('csv' or 'markdown')
 * @returns Blob containing the formatted data
 */
export function generateTableDataDownloadFile(
  columns: { id: string; header?: string }[],
  rows: any[],
  mode: FileType = 'csv',
) {
  const lineEnds = mode === 'markdown' ? '|' : '';
  let headerSeparator = '';

  if (mode === 'markdown') {
    headerSeparator =
      lineEnds + columns.map(() => '---').join('|') + lineEnds + '\n';
  }

  const saveColumns =
    lineEnds +
    columns
      .map((column) => column.header || column.id)
      .join(mode === 'csv' ? ',' : '|') +
    lineEnds;

  const saveRows = rows.map((row) => {
    return (
      lineEnds +
      columns
        .map((column) => {
          // Use lodash get to handle nested paths
          const cellValue = get(row, column.id);
          switch (mode) {
            case 'markdown':
              return cellValue;
            case 'csv':
              if (cellValue === undefined || cellValue === null) {
                return '';
              }
              const cellValueString = Array.isArray(cellValue)
                ? cellValue.join(';')
                : cellValue.toString();
              return cellValueString.includes(',')
                ? `"${cellValueString}"`
                : cellValueString;
          }
        })
        .join(mode === 'csv' ? ',' : '|') +
      lineEnds
    );
  });

  return new Blob(
    [saveColumns + '\n' + headerSeparator + saveRows.join('\n')],
    {
      type: 'text/plain',
    },
  );
}
