import * as React from 'react';
import {
  ColumnDef,
  OnChangeFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Row,
} from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Button,
  Input,
} from '.';

export type TabDef<R extends object, K extends keyof R> = {
  column: K;
  options: {
    name: string;
    value: R[K];
  }[];
};

type NiceTableProps<R extends object = {}, Tab extends keyof R = keyof R> = {
  title?: string;
  data: R[];
  columns: ColumnDef<R>[];
  initialFilter?: string;
  initialSort?: SortingState;
  className?: string;
  enableTotals?: (keyof R)[];
  enableDownloadButton?: boolean;
  onSort?: (v?: SortingState) => void;
  onFilter?: (v?: string) => void;
  initialTab?: R[Tab];
  tabs?: TabDef<R, Tab>;
  onTab?: (v?: R[Tab]) => void;
};

export type SortDef<T> = Array<{ id: keyof T; desc?: boolean }>;

export function NiceTable<R extends object>({
  title,
  data,
  columns,
  initialFilter = '',
  initialSort = [],
  className,
  enableDownloadButton,
  onSort,
  onFilter,
  initialTab,
  tabs,
  onTab,
  enableTotals,
}: NiceTableProps<R>) {
  const [sorting, setSorting] = React.useState<SortingState>(initialSort);
  const [globalFilter, setGlobalFilter] = React.useState<string>(initialFilter);
  const [tab, setTab] = React.useState<R[keyof R] | undefined>(initialTab);

  // Memoize the filtered data
  const filteredData = React.useMemo(() => {
    if (tab && tabs) {
      return data.filter((row) => row[tabs.column] === tab);
    }
    return data;
  }, [data, tab, tabs]);

  const table = useReactTable({
    data: filteredData,
    columns,
    onSortingChange: setSorting as OnChangeFn<SortingState>,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: 'includesString',
    enableSorting: true,
    enableSortingRemoval: true,
  });

  React.useEffect(() => {
    if (onSort) {
      onSort(sorting);
    }
  }, [sorting]);

  React.useEffect(() => {
    if (onFilter) {
      onFilter(globalFilter);
    }
  }, [globalFilter]);

  React.useEffect(() => {
    if (onTab) {
      onTab(tab);
    }
  }, [tab]);

  const generateTableDataDownloadFile = (mode: 'csv' | 'markdown') => {
    const lineEnds = mode === 'markdown' ? '|' : '';
    const headerSeparator =
      lineEnds +
      (mode === 'markdown'
        ? table
            .getAllColumns()
            .map(() => '---')
            .join('|')
        : '') +
      '\n' +
      lineEnds;
    const saveColumns =
      lineEnds +
      table
        .getAllColumns()
        .map((column) => column.id)
        .join(mode === 'csv' ? ',' : '|') +
      lineEnds;
    const saveRows = table.getCoreRowModel().rows.map((row: Row<R>) => {
      return (
        lineEnds +
        row
          .getAllCells()
          .map((cell) => {
            const cellValue = cell.getValue();
            switch (mode) {
              case 'markdown':
                return cellValue;
              case 'csv':
                if (!cellValue) {
                  return cellValue;
                }
                const cellValueString = cellValue.toString();
                if (cellValueString.indexOf(' ') === -1) {
                  return cellValueString;
                }
                return `"${cellValueString}"`;
            }
          })
          .join(mode === 'csv' ? ',' : '|') +
        lineEnds
      );
    });

    const file = new Blob(
      [saveColumns + '\n' + headerSeparator + saveRows.join('\n')],
      { type: 'text/plain' },
    );
    return file;
  };

  // Compute totals if enabled
  const totals = React.useMemo(() => {
    if (!enableTotals || table.getRowModel().rows.length === 0) return [];

    const totalsMap = new Map();
    enableTotals.forEach((key) => totalsMap.set(key, 0));

    table.getRowModel().rows.forEach((row) => {
      enableTotals.forEach((key) => {
        const value = row.getValue(key as string);
        if (typeof value === 'number') {
          totalsMap.set(key, totalsMap.get(key) + value);
        }
      });
    });

    return table.getAllColumns().map((column) => {
      const key = column.id;
      return totalsMap.has(key) ? totalsMap.get(key) : null;
    });
  }, [table.getRowModel().rows, enableTotals, table.getAllColumns()]);

  return (
    <div className={`w-full p-4 ${className}`}>
      {title && <h2 className="text-lg font-semibold mb-4">{title}</h2>}
      <div className="flex items-center py-4 space-x-4">
        <Input
          placeholder="Filter all columns..."
          value={globalFilter || ''}
          onChange={(event) => setGlobalFilter(event.target.value)}
          className="max-w-sm"
        />
        {tabs && (
          <div className="flex space-x-2">
            {tabs.options.map(({ name, value }) => (
              <Button
                key={value as string}
                onClick={() => setTab(value)}
                variant="default"
              >
                {name}
              </Button>
            ))}
          </div>
        )}
        {enableDownloadButton && (
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => {
                const file = generateTableDataDownloadFile('csv');
                const link = document.createElement('a');
                link.href = URL.createObjectURL(file);
                link.download = (title ?? 'table') + '.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              Download CSV
            </Button>
            <Button
              variant="outline"
              onClick={async () => {
                const text = await generateTableDataDownloadFile('csv').text();
                navigator.clipboard.writeText(text);
              }}
            >
              Copy CSV
            </Button>
            <Button
              variant="outline"
              onClick={async () => {
                const text =
                  await generateTableDataDownloadFile('markdown').text();
                navigator.clipboard.writeText(text);
              }}
            >
              Copy Markdown
            </Button>
          </div>
        )}
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {!header.isPlaceholder && header.column.getCanSort() && (
                        <button onClick={() => header.column.toggleSorting()}>
                          <ArrowUpDown className="ml-2 h-4 w-4" />
                        </button>
                      )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
            {enableTotals && (
              <TableRow>
                {totals.map((total, i) => (
                  <TableCell key={i} className="font-semibold">
                    {total}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
