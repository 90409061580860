import { useMemo, useState } from 'react';
import { useRunNumber } from './useRunNumber';
import { useTimeoutRef } from './useTimeoutRef';

/**
 * True if within MS of the value changing to true
 */
export function useWithinMS(
  value: boolean,
  timeout_ms: number,
  memoisations: any[],
): boolean {
  const [within, setW] = useState<boolean>(false);
  const run = useRunNumber(memoisations);
  const timeout = useTimeoutRef(memoisations);

  // If the memoisations changed, we want to return false
  const runOfLastChangeOfMemoisations = useMemo(() => {
    setW(false);
    timeout.clear();
    return run;
  }, memoisations);

  // If the value changed from false to true, we want to return true for the next timeout_ms
  const runOfLastChangeFromFalseToTrue = useMemo(() => {
    if (value && run !== 1) {
      setW(true);
      timeout.set(() => {
        setW(false);
      }, timeout_ms);
      return run;
    }
    return false;
  }, [value, ...memoisations]);

  // Catch any changes that happened within the memo calls of this run
  let withinMS = within;
  if (run === runOfLastChangeOfMemoisations) {
    withinMS = false;
  }
  if (run === runOfLastChangeFromFalseToTrue) {
    withinMS = true;
  }

  return withinMS;
}
