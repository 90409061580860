import { useMemo, useRef } from 'react';

export function useTimeoutRef(memoisations: unknown[]): {
  set(callback: () => void, ms: number): void;
  clear(): void;
} {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const obj = useMemo(() => {
    function clear() {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
      }
    }

    // In case memoisations change, clear previous timeouts
    clear();

    return {
      set(callback: () => void, ms: number) {
        clear();
        timeout.current = setTimeout(callback, ms);
      },
      clear,
    };
  }, memoisations);

  return obj;
}
