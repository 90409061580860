import { sleep } from '@numbereight/utils';
import type {
  Calculation,
  CalculationContext,
  CalculationInput,
  CalculationOutput,
} from '../Calculator';
import type { CalculationPaths } from '../calculations.autogen';
import { ServerContext } from './AppContext';

const TIMEOUT_BASE = 30 * 1000;
const MAX_RETRIES = 5;

async function call(url: string, body: string | undefined, retryCount: number) {
  const response = await fetch(url, {
    method: 'POST',
    body,
    headers: body ? { 'Content-Type': 'application/json' } : undefined,
  });

  if (response.status === 504) {
    if (retryCount < MAX_RETRIES) {
      const backOffTime = Math.pow(TIMEOUT_BASE, retryCount);
      console.log(
        `Was trying to run calculation that timed out. Sleeping before retrying`,
        { url, retryCount, status: response.status, backOffTime },
      );
      await sleep(backOffTime);
      console.log(`Retrying calculation that timed out on last attempt`, {
        url,
        retryCount,
        status: response.status,
        backOffTime,
      });
      return await call(url, body, retryCount + 1);
    } else {
      throw new Error(
        `${response.status}: Retried this ${retryCount} number of times, and failed.`,
      );
    }
  }

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  return await response.json();
}

export async function calcV1<
  C extends Calculation<
    CalculationContext<C>,
    CalculationInput<C>,
    CalculationOutput<C>
  >,
>({
  calculationUrl,
  body,
  options,
}: {
  calculationUrl: CalculationPaths;
  body: CalculationInput<C> | JSONishNode;
  options?: Partial<ServerContext>;
}): Promise<CalculationOutput<C>> {
  const matches = calculationUrl.match(/^(\/api\/v1)?(\/)?([^\/].+)$/);
  const calculation = matches?.[3] ?? null;
  if (calculation === null) {
    throw new Error(`Invalid calculation url: "${calculation}"`);
  }
  const url = `${options?.host ?? ''}/api/v1/${calculation}`;

  return await call(url, body ? JSON.stringify(body) : undefined, 0);
}
