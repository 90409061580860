import React, { useState } from 'react';
import debounceComponent from 'react-debounce-render';
import Plot from 'react-plotly.js';
import { useWatchDimensions } from './useWatchDimensions';
import { AxisType } from 'plotly.js';

const DebouncedPlot = debounceComponent(Plot, 100);

export function GenericGraph({
  data,
  title,
  groupBy,
  x_index,
  y_index,
  x_label,
  y_label,
  width,
  thresholded,
  y_min,
  initialShowLog,
  tickWidth,
  additionalLayoutParams,
}: {
  data: { [k: string]: any }[];
  title: string;
  x_label: string;
  y_label: string;
  width: number | null;
  thresholded?: { key: string; default: number }[];
  y_min?: number;
  initialShowLog?: boolean;
  tickWidth?: number;
  additionalLayoutParams?: { [k: string]: any };
} & (
  | { groupBy: string; x_index: string; y_index: string }
  | { groupBy?: never; x_index?: never; y_index?: never }
)) {
  const [showLog, setShowLog] = useState<boolean>(initialShowLog ?? false);

  const [ref, dimensions] = useWatchDimensions<HTMLDivElement>();
  const type: AxisType | undefined = showLog ? 'log' : undefined;
  const layout = {
    showlegend: true,
    autosize: true,
    width: width ?? dimensions.width,
    height: 600,
    yaxis: {
      type,
      title: y_label,
      range: [y_min ? y_min : 0, null],
    },
    xaxis: {
      title: x_label,
      ...(tickWidth && { dtick: tickWidth * 24 * 60 * 60 * 1000 }),
    },
    title,
    hoverlabel: { namelength: -1 },
    ...(additionalLayoutParams ?? {}),
  };

  const config = {
    responsive: true,
    scrollZoom: true,
  };
  const thresholdStates = [];
  if (thresholded) {
    for (const t of thresholded) {
      const [threshold, setThreshold] = useState(t.default);
      thresholdStates.push({ threshold, setThreshold, key: t.key });
    }
  }

  const graph_data: Plotly.Data[] = [];
  if (groupBy && x_index && y_index) {
    const uniques = new Set(data.map((x) => x[groupBy]));
    for (const unique of uniques) {
      let filteredData = data.filter((d) => d[groupBy] === unique);
      if (thresholded) {
        for (const t of thresholdStates) {
          filteredData = filteredData.filter((d) => d[t.key] > t.threshold);
        }
      }
      const xs = filteredData.map((d) => d[x_index]);
      const ys = filteredData.map((d) => d[y_index]);
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };

      const hoverText = xs.map((date) =>
        new Date(date).toLocaleDateString('en-US', options),
      );

      graph_data.push({
        x: xs,
        y: ys,
        type: 'scatter' as const,
        name: unique,
        text: hoverText,
        hovertemplate: '%{text}',
      });
    }
  }
  return (
    <>
      <div ref={ref}>
        <br />
        Log scale:{' '}
        <input
          type="checkbox"
          defaultChecked={showLog}
          onChange={(e) => setShowLog(e.target.checked)}
        />
        <br />
        {thresholdStates.map((thresholdState, i) => {
          return (
            <div key={i}>
              <label>
                {thresholded ? `${thresholded[i].key} threshold: ` : ''}
              </label>
              <input
                type="number"
                value={thresholdState.threshold}
                onChange={(e) => {
                  thresholdState.setThreshold(parseFloat(e.target.value));
                }}
              />
            </div>
          );
        })}
        <DebouncedPlot
          data={groupBy ? graph_data : data}
          layout={layout}
          config={config}
        />
      </div>
    </>
  );
}
