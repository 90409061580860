import React from 'react';

/**
 * A comment for a form field
 */
export function Comment(props: React.PropsWithChildren<{}>) {
  // TODO: move to less file
  return (
    <p
      style={{
        color: '#555',
        marginTop: '0.1em',
        textAlign: 'center',
        fontSize: '0.8em',
      }}
    >
      {props.children}
    </p>
  );
}
