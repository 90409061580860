import { RefinementError } from "@normed/refinements";

// Test if value is an instance of the given class. Only works if the class is publicly constructable.
// This is here because prettier will crash on the `new(...args: any[] => InstanceType<T>)`
// prettier-ignore
export function refineInstanceOf<T extends abstract new(...args: any[]) => InstanceType<T>>(t: T): (path: string[], value: unknown) => RefinementError | InstanceType<T> {
    return (path, v) => {
        if(typeof v === 'object' && v instanceof t) {
            return v;
        }
        return new RefinementError(path, `should be an instance of ${t.name}`);
    }
}

export function refineJSONishMember(path: string[], v: unknown): RefinementError | JSONishMember {
    try {
        JSON.stringify(v);
    } catch(e) {
        return new RefinementError(path, 'is not a JSONishObject');
    }
    return v as JSONishMember;
}