import React from 'react';
import Plot from 'react-plotly.js';

export function PlotBar<
  D extends { [k: string]: D[string] },
  XKey extends keyof D,
  YKey extends keyof D,
  XValue extends string | number,
  YValue extends string | number,
>({
  rows,
  xKey,
  yKey,
  sort,
  transformX,
  transformY,
  logPlot,
  filter,
  transforms,
  colour,
  title,
}: {
  rows: D[];
  xKey: XKey;
  transformX: (v: D[XKey], r: D) => XValue;
  yKey: YKey;
  transformY: (v: D[YKey], r: D) => YValue;
  sort: (
    a: { x: XValue; y: YValue; row: D },
    b: { x: XValue; y: YValue; row: D },
  ) => number;
  colour?: (r: D) => string;
  logPlot?: boolean;
  filter?: (r: D) => boolean;
  transforms?: Plotly.DataTransform[];
  title?: string;
}) {
  const data = rows.filter(filter ? filter : () => true).map((row) => {
    return {
      row,
      x: transformX(row[xKey], row),
      y: transformY(row[yKey], row),
      color: colour ? colour(row) : undefined,
    };
  });

  data.sort(sort);

  // Turn it into a data row for Plotly
  const { x, y, color } = data.reduce(
    (acc, { x, y, color }) => {
      acc.x.push(x);
      acc.y.push(y);
      acc.color.push(color);
      return acc;
    },
    { x: [], y: [], color: [] } as {
      x: XValue[];
      y: YValue[];
      color: (string | undefined)[];
    },
  );
  const plotData: Plotly.Data[] = [
    {
      x,
      y,
      transforms: transforms ?? [],
      type: 'bar' as const,
      marker: {
        color: colour ? color : undefined,
      },
    },
  ];
  const layout = {
    yaxis: {
      type: logPlot ? ('log' as const) : undefined,
    },
    title,
  };

  return <Plot data={plotData} layout={layout} config={{ responsive: true }} />;
}
