/**
 * A CTA for getting users to login
 */

import React from 'react';
import { CTA, UserContext } from '.';
import { Button } from './Button';

export function LoginCTA() {
  return (
    <UserContext.Consumer>
      {(user) => (
        <CTA
          headline="You aren't logged in"
          text="...so we can't show you anything"
        >
          <Button
            onClick={user.login}
            fallback="/auth/google"
            disabled={user.loading}
            text={user.loading ? '...loading' : 'Login'}
          />
        </CTA>
      )}
    </UserContext.Consumer>
  );
}
