import React from 'react';
import { NiceTable } from './NiceTable';
import { useMagicColumns } from './useMagicColumns';
import { SortingState } from '@tanstack/react-table';

export function MagicTable<V extends { [k: string]: V[keyof V] }>({
  data,
  initialSort,
  enableDownloadButton,
  title,
  enableTotals,
}: {
  data: V[];
  initialSort?: SortingState;
  enableDownloadButton?: boolean;
  title?: string;
  enableTotals?: (keyof V)[];
}) {
  // Ideally we would pass the NiceTable interface through to MagicTable so it is more customizable.
  // However, in attempting to do so, it was harder than expected, so just passing through the required
  // parameter (initialSort) manually for now, and we can deal with this later.
  const columns = useMagicColumns(data);
  return (
    <NiceTable
      title={title}
      data={data}
      columns={columns}
      initialSort={initialSort}
      enableTotals={enableTotals}
      enableDownloadButton={enableDownloadButton}
    />
  );
}
