import React from 'react';
import { createRoot } from 'react-dom/client';
import { User } from '../services/Auth';
import {
  LoginCTA,
  Banner,
  CenterHV,
  MainArea,
  AppContextProvider,
  UserContext,
  ErrorBoundary,
} from '../react-components';
import { useUser } from '../react-components/useUser';
import { ChevronRight } from 'lucide-react';

function PageWrapper({
  title,
  children,
  isHomePage = false,
}: {
  title: string;
  children: React.ReactNode;
  isHomePage?: boolean;
}) {
  return (
    <div className="container mx-auto px-4 max-w-7xl pb-20">
      {/* Breadcrumbs - Only shown if title exists and it's not the homepage */}
      {title && !isHomePage && (
        <div className="flex items-center text-broccoli-neutral-500 mt-10 mb-6">
          <a
            href="/"
            className="text-broccoli-primary-600 hover:text-broccoli-primary-700 transition-colors text-sm"
          >
            Home
          </a>
          <ChevronRight size={14} className="mx-2" />
          <span className="text-broccoli-neutral-600 text-sm font-medium">
            {title}
          </span>
        </div>
      )}

      {/* Page Title - Only shown if it's not the homepage */}
      {title && !isHomePage && (
        <h1 className="text-3xl font-bold mb-6 text-broccoli-neutral-800">
          {title}
        </h1>
      )}

      {/* Page Content */}
      {children}
    </div>
  );
}

function Page({
  title,
  Content,
}: {
  title?: string;
  Content: React.ComponentType<{ user: User }>;
}) {
  const user = useUser();
  const isHomePage = title === 'Broccoli'; // Check if it's the homepage

  return (
    <AppContextProvider user={user}>
      <Banner />
      <MainArea>
        <UserContext.Consumer>
          {(user) => {
            if (user?.data) {
              return (
                <ErrorBoundary>
                  <PageWrapper title={title || ''} isHomePage={isHomePage}>
                    <Content user={user.data} />
                  </PageWrapper>
                </ErrorBoundary>
              );
            } else {
              return (
                <CenterHV>
                  <LoginCTA />
                </CenterHV>
              );
            }
          }}
        </UserContext.Consumer>
      </MainArea>
    </AppContextProvider>
  );
}

export function BasicPage(content: React.ComponentType<any>, title?: string) {
  document.addEventListener('DOMContentLoaded', function () {
    const root = document.querySelector('#react-root');
    if (!root) {
      throw new Error(`Missing root element`);
    }
    createRoot(root).render(<Page Content={content} title={title} />);
  });
}
