import React from 'react';
import { UserContext } from '.';
import { LogIn, LogOut } from 'lucide-react';

export function Banner({ title, beta }: { title?: string; beta?: boolean }) {
  const str = [beta ? 'BETA' : undefined, title?.toUpperCase()]
    .filter(Boolean)
    .join(' - ');

  return (
    <header className="bg-gradient-to-r from-uicore-primary to-uicore-secondary w-full py-6 flex items-center justify-between shadow-lg align-baseline">
      <div className="ml-4">
        <h1 className="text-white m-0 text-2xl font-bold">
          <a href="/" className="text-white no-underline">
            {str}
          </a>
        </h1>
      </div>
      <ProfileBox className="mr-4" />
    </header>
  );
}

function ProfileBox({ className }: { className: string }) {
  return (
    <UserContext.Consumer>
      {(user) => (
        <div className={`${className} flex items-center space-x-4`}>
          {user.data ? (
            <>
              <span className="text-xl font-small-caps text-white capitalize">
                {user.data.givenName || ''}
              </span>
              <a
                href="/logout"
                onClick={user.logout}
                className="flex items-center text-white no-underline hover:text-uicore-body"
              >
                <LogOut className="w-5 h-5 mr-1" />
                Logout
              </a>
            </>
          ) : (
            <>
              <span className="text-xl font-small-caps text-white">?</span>
              <a
                href="/auth/google"
                onClick={user.login}
                className="flex items-center text-white no-underline hover:text-uicore-body"
              >
                <LogIn className="w-5 h-5 mr-1" />
                Login
              </a>
            </>
          )}
        </div>
      )}
    </UserContext.Consumer>
  );
}
