import React, { ReactElement, useEffect } from 'react';
import { APIItem, isAPIItem } from '../APIItem';
import { Nested, Flattened } from '../utils';
import { internalFetch, PromiseCard, Section, usePromiseFunction } from '.';

function APITree({
  nested,
  Render,
}: {
  nested: Nested<APIItem>;
  Render: (props: { item: APIItem }) => ReactElement;
}) {
  return (
    <div className="APITree__Container">
      {Object.entries(nested).map(([key, value]) => {
        if (isAPIItem(value)) {
          return <APILeaf item={value} Render={Render} key={key} />;
        } else {
          return (
            <Section key={key} title={key} className="APITree__Node">
              <APITree nested={value} Render={Render} />
            </Section>
          );
        }
      })}
    </div>
  );
}
function APILeaf({
  item,
  Render,
}: {
  item: APIItem;
  Render: (props: { item: APIItem }) => ReactElement;
}) {
  return (
    <Section title={item.name} className="APITree__Leaf" collapsed={true}>
      <Render item={item} />
    </Section>
  );
}

export function APIList({
  Render,
}: {
  Render: (props: { item: APIItem }) => ReactElement;
}) {
  const apiList = usePromiseFunction(() =>
    internalFetch<{
      flattened: Flattened<APIItem>;
      nested: Nested<APIItem>;
    }>({
      url: '/api/v1/index',
      method: 'POST',
    }),
  );
  useEffect(apiList.run, []);

  return (
    <PromiseCard
      promiseState={apiList}
      child={({ nested }) => (
        <APITree nested={nested} key="APIList" Render={Render} />
      )}
    />
  );
}
