/**
 * Default broccoli web page
 * All other pages a user has permission to access should be reachable through this one
 */
import React from 'react';
import { User } from '../services/Auth';
import { BasicPage } from '../react-components';
import { pages } from '../html.autogen';
import {
  LayoutDashboard,
  BarChart2,
  FileText,
  Settings,
  Users,
  Database,
  Terminal,
  Activity,
  Monitor,
  AlertCircle,
  Search,
  BarChart,
  Box,
  Share,
  Archive,
  Headphones,
  Smartphone,
  Package,
  ChevronRight,
  Info,
} from 'lucide-react';

type Pages = { [k: string]: Pages | string };

const getCategoryIcon = (name: string) => {
  const iconMap: Record<string, React.ReactNode> = {
    'Dashboard': <LayoutDashboard size={20} />,
    'Analytics': <BarChart2 size={20} />,
    'Reports': <FileText size={20} />,
    'Settings': <Settings size={20} />,
    'Users': <Users size={20} />,
    'Database': <Database size={20} />,
    'Terminal': <Terminal size={20} />,
    'Activity': <Activity size={20} />,
    'Monitoring': <Monitor size={20} />,
    'Alerts': <AlertCircle size={20} />,
    'Search': <Search size={20} />,
    'Statistics': <BarChart size={20} />,
    'Resources': <Box size={20} />,
    'Integrations': <Share size={20} />,
    'Archived': <Archive size={20} />,
    'Engineering': <Terminal size={20} />,
    'Behavioral': <BarChart2 size={20} />,
    'Affinity_&_Contextual': <Share size={20} />,
    'Podcast': <Headphones size={20} />,
    'App': <Smartphone size={20} />,
    'Curation': <Package size={20} />,
    'Reporting': <FileText size={20} />,
  };

  return iconMap[name] || <Box size={20} />;
};

const CategoryIcon = ({ name }: { name: string }) => {
  return (
    <div className="w-10 h-10 rounded-lg bg-broccoli-primary-100 text-broccoli-primary-600 flex items-center justify-center mr-3">
      {getCategoryIcon(name.replace(/_/g, ' '))}
    </div>
  );
};

const hasActualContent = (pagesObj: Pages | string): boolean => {
  if (typeof pagesObj === 'string') return true;

  const keys = Object.keys(pagesObj);
  if (keys.length === 0) return false;
  if (keys.length > 1) return true;
  return !(keys.length === 1 && keys[0] === 'index');
};

// Define the custom ordering for top-level categories
const getCategoryOrder = (category: string): number => {
  const orderMap: Record<string, number> = {
    'Engineering': 1,
    'Behavioral': 2,
    'Affinity_&_Contextual': 3,
    // Add other categories above Archived if needed
    'Archived': 99, // Always put Archived at the end
  };

  return orderMap[category] || 50; // Default to middle value for undefined categories
};

// Custom sort functions for subcategories
const sortAffinityContextual = (a: string, b: string): number => {
  const affinityOrder: Record<string, number> = {
    Podcast: 1,
    App: 2,
    Curation: 3,
    index: 0, // Always put index first
  };

  return (affinityOrder[a] || 50) - (affinityOrder[b] || 50);
};

const sortPodcast = (a: string, b: string): number => {
  const podcastOrder: Record<string, number> = {
    Monitoring: 1,
    Distributions: 2,
    Impressions: 3,
    index: 0,
  };

  return (podcastOrder[a] || 50) - (podcastOrder[b] || 50);
};

const sortApp = (a: string, b: string): number => {
  const appOrder: Record<string, number> = {
    Monitoring: 1,
    Distributions: 2,
    Categories: 3,
    Analysis: 4,
    index: 0,
  };

  return (appOrder[a] || 50) - (appOrder[b] || 50);
};

const sortEngineering = (a: string, b: string): number => {
  const engineeringOrder: Record<string, number> = {
    API_Keys: 1,
    Cloud_Device_Audiences: 2,
    Model_Runner: 3,
    Sanity_Check: 4,
    Status_Logs: 5,
    index: 0,
  };

  return (engineeringOrder[a] || 50) - (engineeringOrder[b] || 50);
};

const sortBehavioral = (a: string, b: string): number => {
  const behavioralOrder: Record<string, number> = {
    Monitoring: 1,
    Reporting: 2,
    index: 0,
  };

  return (behavioralOrder[a] || 50) - (behavioralOrder[b] || 50);
};

function MainContentNode({
  pages,
  user,
  parentCategory = '',
}: {
  pages: Pages;
  user: User;
  parentCategory?: string;
}) {
  // This handles non-top level nodes (within cards)
  let keys = Object.keys(pages);

  // Apply appropriate sorting based on parent category
  if (parentCategory === 'Engineering') {
    keys.sort(sortEngineering);
  } else if (parentCategory === 'Behavioral') {
    keys.sort(sortBehavioral);
  } else if (parentCategory === 'Affinity_&_Contextual') {
    keys.sort(sortAffinityContextual);
  } else if (parentCategory === 'Podcast') {
    keys.sort(sortPodcast);
  } else if (parentCategory === 'App') {
    keys.sort(sortApp);
  } else {
    // Default alphabetical sort for other categories
    keys.sort();
  }

  return (
    <ul className="space-y-2 list-none">
      {keys.map((key) => {
        const v = pages[key];
        if (key === 'Archived' && !user.permissions.includes('archived')) {
          return null;
        }

        // Convert key name for display
        const displayName = key === 'index' ? 'Home' : key.replace(/_/g, ' ');

        // Case 1: Direct link
        if (typeof v === 'string') {
          return (
            <li key={key}>
              <a
                href={v}
                className="flex items-center px-4 py-2.5 font-medium rounded-md text-broccoli-primary-600 hover:bg-broccoli-primary-50 hover:text-broccoli-primary-700 transition-colors group"
              >
                <span className="flex-grow">{displayName}</span>
                <ChevronRight
                  size={16}
                  className="opacity-0 group-hover:opacity-100 transition-opacity ml-2"
                />
              </a>
            </li>
          );
        }

        // Case 2: Has index file
        else if (typeof v['index'] === 'string') {
          const withoutIndex = { ...v };
          delete withoutIndex['index'];

          return (
            <li key={key}>
              <a
                href={v['index']}
                className="flex items-center px-4 py-2.5 font-medium rounded-md text-broccoli-primary-600 hover:bg-broccoli-primary-50 hover:text-broccoli-primary-700 transition-colors group"
              >
                <span className="flex-grow">{displayName}</span>
                <ChevronRight
                  size={16}
                  className="opacity-0 group-hover:opacity-100 transition-opacity ml-2"
                />
              </a>
              {Object.keys(withoutIndex).length > 0 && (
                <div className="pl-4 border-l-2 border-broccoli-neutral-200 ml-5 mt-1 space-y-1.5">
                  <MainContentNode
                    pages={withoutIndex}
                    user={user}
                    parentCategory={key}
                  />
                </div>
              )}
            </li>
          );
        }

        // Case 3: Category without index
        else {
          return (
            <li key={key}>
              <div className="font-medium text-broccoli-neutral-700 px-4 py-2">
                {displayName}
              </div>
              <div className="pl-4 border-l-2 border-broccoli-neutral-200 ml-5 space-y-1.5">
                <MainContentNode pages={v} user={user} parentCategory={key} />
              </div>
            </li>
          );
        }
      })}
    </ul>
  );
}

function RecentUpdates() {
  return (
    <div className="bg-broccoli-primary-50 border border-broccoli-primary-100 rounded-lg p-6 mb-8">
      <h2 className="flex items-center text-lg font-semibold mb-4 text-broccoli-neutral-800">
        <div className="w-8 h-8 rounded-full bg-broccoli-primary-100 text-broccoli-primary-600 flex items-center justify-center mr-3">
          <Info size={16} />
        </div>
        Recent updates
      </h2>
      <div className="space-y-3 ml-11">
        <div className="flex items-start">
          <Activity
            size={16}
            className="text-broccoli-primary-500 mr-2 mt-1 flex-shrink-0"
          />
          <p className="text-broccoli-neutral-700">
            Navigation restructured with new Behavioral and Affinity &
            Contextual sections
          </p>
        </div>
        <div className="flex items-start">
          <Archive
            size={16}
            className="text-broccoli-primary-500 mr-2 mt-1 flex-shrink-0"
          />
          <p className="text-broccoli-neutral-700">
            App Report has been retired and moved to Archives
          </p>
        </div>
      </div>
    </div>
  );
}

function CategoryGrid({ pages, user }: { pages: Pages; user: User }) {
  let categories = Object.keys(pages);

  // Filter out categories that only have an index page
  categories = categories.filter((category) => {
    if (category === 'index') return false;
    if (typeof pages[category] === 'string') return true;
    return hasActualContent(pages[category]);
  });

  // Use custom sort for top-level
  categories.sort((a, b) => getCategoryOrder(a) - getCategoryOrder(b));

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {categories.map((category) => {
        if (category === 'Archived' && !user.permissions.includes('archived')) {
          return null;
        }

        const v = pages[category];
        const displayName = category.replace(/_/g, ' ');

        return (
          <div
            key={category}
            className="bg-white rounded-lg border border-broccoli-neutral-200 shadow-sm hover:shadow-md transition-shadow"
          >
            {/* Case 1: Direct link */}
            {typeof v === 'string' && (
              <a
                href={v}
                className="flex items-center p-5 text-lg font-semibold text-broccoli-primary-600 hover:text-broccoli-primary-500 transition-colors group"
              >
                <CategoryIcon name={category} />
                <span className="flex-grow">
                  {category === 'index' ? 'Home' : displayName}
                </span>
                <ChevronRight
                  size={18}
                  className="opacity-0 group-hover:opacity-100 transition-opacity ml-2"
                />
              </a>
            )}

            {/* Case 2: Has index file */}
            {typeof v === 'object' && typeof v['index'] === 'string' && (
              <>
                <a
                  href={v['index']}
                  className="flex items-center p-5 text-lg font-semibold border-b border-broccoli-neutral-200 text-broccoli-primary-600 hover:text-broccoli-primary-500 transition-colors group"
                >
                  <CategoryIcon name={category} />
                  <span className="flex-grow">{displayName}</span>
                  <ChevronRight
                    size={18}
                    className="opacity-0 group-hover:opacity-100 transition-opacity ml-2"
                  />
                </a>

                {/* Show children without the index */}
                {(() => {
                  const withoutIndex = { ...v };
                  delete withoutIndex['index'];
                  return Object.keys(withoutIndex).length > 0 ? (
                    <div className="p-3">
                      <MainContentNode
                        pages={withoutIndex}
                        user={user}
                        parentCategory={category}
                      />
                    </div>
                  ) : null;
                })()}
              </>
            )}

            {/* Case 3: Category without index */}
            {typeof v === 'object' && typeof v['index'] === 'undefined' && (
              <>
                <h2 className="flex items-center p-5 text-lg font-semibold border-b border-broccoli-neutral-200 text-broccoli-neutral-800">
                  <CategoryIcon name={category} />
                  {displayName}
                </h2>
                <div className="p-3">
                  <MainContentNode
                    pages={v}
                    user={user}
                    parentCategory={category}
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

const capitalizeFirstLetter = (name: string) => {
  if (!name) return '';
  return name.charAt(0).toUpperCase() + name.slice(1);
};

function HomePage({ user }: { user: User }) {
  const displayName = capitalizeFirstLetter(user?.givenName) || 'User';
  return (
    <div className="pt-10">
      <h1 className="text-3xl font-bold mb-2 text-broccoli-neutral-800">
        Welcome, {displayName}
      </h1>
      <p className="text-broccoli-neutral-600 text-lg mb-6">
        Access your tools and resources from this dashboard. Select a category
        below to get started.
      </p>

      <div className="mt-4 mb-4">
        <RecentUpdates />
      </div>

      <div className="mt-4">
        <CategoryGrid pages={pages} user={user} />
      </div>
    </div>
  );
}

export default BasicPage(HomePage, 'Broccoli');
