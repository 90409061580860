/**
 * Center elements horizontally and vertically by containing them inside a single div
 */

import React, { PropsWithChildren } from 'react';

function Center(name: string) {
  return function Centre({
    children,
    className,
  }: PropsWithChildren<{ className?: string }>) {
    return <div className={`${name}__Container ${className}`}>{children}</div>;
  };
}

export const CenterHV = Center('CenterHV');
export const CenterH = Center('CenterH');
export const CenterV = Center('CenterV');
