import React, { ComponentType, PropsWithChildren, ReactNode } from 'react';
import { User, DefaultUser } from './useUser';

export type ServerContext = {
  host: string;
};

export type AppContext = {
  user: User;
};

export const UserContext = React.createContext<User>(DefaultUser);
UserContext.displayName = 'UserContext';

const DefaultServer = {
  host: window.location.origin,
};

export const ServerContext = React.createContext<ServerContext>(DefaultServer);
ServerContext.displayName = 'ServerContext';

export function AppContextProvider({
  children,
  user,
}: PropsWithChildren<AppContext>) {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function ServerContextProvider({
  children,
  server,
}: PropsWithChildren<{ server: ServerContext }>) {
  return (
    <ServerContext.Provider value={server}>{children}</ServerContext.Provider>
  );
}

export function withContext<Context>(Context: React.Context<Context>) {
  return <WrappedProps extends {}>(
    WrappedComponent: ComponentType<WrappedProps & Context>,
  ) => {
    return (props: Omit<WrappedProps, keyof Context>): ReactNode => {
      return (
        <Context.Consumer>
          {(context) => (
            // @ts-expect-error : Omit<WrappedProps, keyof Context> is not quite right - in the case that WrappedProps contains a key that collids with Context
            <WrappedComponent {...Object.assign({}, props, context)} />
          )}
        </Context.Consumer>
      );
    };
  };
}
