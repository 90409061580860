import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import { mergeCSSClasses } from './shadcnUtils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={mergeCSSClasses('border-b', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex w-full">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={mergeCSSClasses(
        'flex flex-1 items-center justify-between py-4 font-medium text-gray-800 transition-all duration-200 hover:underline [&[data-state=open]>svg]:rotate-180',
        className,
      )}
      {...props}
    >
      <div className="flex-1">{children}</div>
      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={mergeCSSClasses(
      'overflow-visible text-sm transition-all duration-300 data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className,
    )}
    {...props}
  >
    <div className="pb-4 pt-0">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };

export function Section({
  title,
  collapsed: initialCollapsed,
  onCollapse,
  children,
  className,
  anchor,
}: PropsWithChildren<{
  title: string | ReactElement;
  className?: string;
  collapsed?: boolean;
  onCollapse?: (collapsed: boolean) => void;
  anchor?: string;
}>) {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  return (
    <ErrorBoundary>
      <Accordion
        type="single"
        collapsible
        className={mergeCSSClasses(
          'border-l-4 pl-4 my-4 border-uicore-primary rounded-md transition-all duration-300',
          className,
        )}
        value={collapsed ? '' : 'item-1'}
        onValueChange={(value) => {
          const isCollapsed = value !== 'item-1';
          setCollapsed(isCollapsed);
          onCollapse?.(isCollapsed);
        }}
      >
        <AccordionItem value="item-1">
          <AccordionTrigger>
            {anchor ? <a href={`#${anchor}`} className="mr-2"></a> : null}
            {title}
          </AccordionTrigger>
          <AccordionContent>{children}</AccordionContent>
        </AccordionItem>
      </Accordion>
    </ErrorBoundary>
  );
}
