import { RefinementFunction } from '@normed/refinements';
import { ErrorWithMeta } from '@numbereight/utils';

export async function internalFetch<
  Response extends JSONishMember = JSONishMember,
  Request extends JSONishMember = JSONishMember,
>({
  url,
  method,
  body,
  refinement,
}: {
  url: string;
  method?: 'GET' | 'POST';
  body?: null | Request;
  refinement?: RefinementFunction<Response>;
}): Promise<Response> {
  const response = await fetch(url, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: body ? { 'Content-Type': 'application/json' } : undefined,
  });

  if (!response.ok) {
    // Unsure if this is a ClientPresentableError or not.
    throw new ErrorWithMeta(`${response.status}: ${response.statusText}`, {
      body: await response
        .text()
        .catch((_thrown) => '<Failed to parse response text to get error.>'),
    });
  }

  const json: JSONishMember = await response.json();

  if (!refinement) {
    // @ts-expect-error : no refinement is provided, so its a hard cast : // TODO: remove
    return json;
  }

  const result = refinement([url], json);
  if (result instanceof Error) {
    throw result;
  }

  return result;
}
