/*
 * WARNING: This file is autogenerated by ../../autogen/bundles/bin/start.js
 */

export const pages = {
  'Affinity_&_Contextual': {
    App: {
      Analysis: { index: '/Affinity_&_Contextual/App/Analysis/index.html' },
      Categories: { index: '/Affinity_&_Contextual/App/Categories/index.html' },
      Distributions: {
        index: '/Affinity_&_Contextual/App/Distributions/index.html',
      },
      Monitoring: { index: '/Affinity_&_Contextual/App/Monitoring/index.html' },
    },
    Curation: {
      Package_Curation: {
        index: '/Affinity_&_Contextual/Curation/Package_Curation/index.html',
      },
    },
    Podcast: {
      Distributions: {
        index: '/Affinity_&_Contextual/Podcast/Distributions/index.html',
      },
      Impressions: {
        index: '/Affinity_&_Contextual/Podcast/Impressions/index.html',
      },
      Monitoring: {
        index: '/Affinity_&_Contextual/Podcast/Monitoring/index.html',
      },
    },
    Web: {
      Contextual: { index: '/Affinity_&_Contextual/Web/Contextual/index.html' },
    },
  },
  'Archived': {
    App_Report: { index: '/Archived/App_Report/index.html' },
    audiences: '/Archived/audiences.html',
    bqingest: '/Archived/bqingest.html',
    calcs: '/Archived/calcs.html',
    construction: {
      euclid_lite_health: {
        health: '/Archived/construction/euclid_lite_health/health.html',
      },
    },
    context: '/Archived/context.html',
    latency: '/Archived/latency.html',
    log: '/Archived/log.html',
    storybook: '/Archived/storybook.html',
  },
  'Behavioral': {
    Monitoring: { index: '/Behavioral/Monitoring/index.html' },
    Reporting: { index: '/Behavioral/Reporting/index.html' },
  },
  'Engineering': {
    API_Keys: '/Engineering/API_Keys.html',
    Cloud_Device_Audiences: '/Engineering/Cloud_Device_Audiences.html',
    Model_Runner: '/Engineering/Model_Runner.html',
    Sanity_Check: '/Engineering/Sanity_Check.html',
    Status_Logs: '/Engineering/Status_Logs.html',
  },
  'index': '/index.html',
} as const;
