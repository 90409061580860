/**
 * A CTA
 */

import React, { PropsWithChildren } from 'react';

export function CTA({
  headline,
  text,
  children,
}: PropsWithChildren<{ headline?: string; text?: string }>) {
  return (
    <div className="p-4 border-4 border-transparent border-gradient-to-r from-broccoli-primary-600 to-broccoli-secondary-600 flex flex-col items-center justify-center">
      {headline ? (
        <h2 className="text-broccoli-neutral-900 m-0 p-0">{headline}</h2>
      ) : null}
      {text ? <p className="text-broccoli-neutral-600">{text}</p> : null}
      {children}
    </div>
  );
}
