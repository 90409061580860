import { asError } from '@numbereight/utils';
import React, { useEffect, useMemo, useReducer } from 'react';

type SimplePromiseState<T> = {
  attempt: null | symbol;
  loaded: boolean; // If this has ever successfully loaded
  loading: boolean; // Is a new value currently loading
  success: boolean; // Was the last request successful
  value: null | T; // The last successful result
  error: null | Error; // The last unsuccessful error
};

export type PromiseAction<T> =
  | {
      type: 'error';
      error: Error | null;
      attempt: symbol;
    }
  | {
      type: 'success';
      value: null | T;
      attempt: symbol;
    }
  | {
      type: 'run';
      attempt: symbol;
    }
  | {
      type: 'reset';
    };

function reducer<T>(
  state: SimplePromiseState<T>,
  action: PromiseAction<T>,
): SimplePromiseState<T> {
  switch (action.type) {
    case 'reset':
      return {
        attempt: null,
        loaded: false,
        loading: false,
        success: false,
        value: null,
        error: null,
      };
    case 'run':
      return {
        ...state,
        attempt: action.attempt,
        loading: true,
      };
    case 'error':
      if (action.attempt !== state.attempt) {
        return state;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        success: false,
        error: action.error,
      };
    case 'success':
      if (action.attempt !== state.attempt) {
        return state;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        success: true,
        value: action.value,
      };
  }
}

type Reducer<T> = (
  state: SimplePromiseState<T>,
  action: PromiseAction<T>,
) => SimplePromiseState<T>;

export type PromiseState<T> = SimplePromiseState<T> & {
  clear(): void;
  run(): void;
  useLatest<R>(
    transformer: (v: T) => R,
    fallback: R,
    memoisations: unknown[],
  ): R;
  unran(): boolean;
  dispatch: React.Dispatch<PromiseAction<T>>;
};

export function usePromiseState<T>(
  memoisations: unknown[] = [],
): PromiseState<T> {
  const [state, dispatch] = useReducer<Reducer<T>>(reducer, {
    attempt: null,
    loaded: false,
    loading: false,
    success: false,
    value: null,
    error: null,
  });

  useEffect(() => {
    if (state.attempt !== null) dispatch({ type: 'reset' });
  }, memoisations);

  return Object.assign(state, {
    clear: () => dispatch({ type: 'reset' }),
    run: () => dispatch({ type: 'run', attempt: Symbol() }),
    useLatest<R>(
      transformer: (v: T) => R,
      fallback: R,
      memoisations: unknown[],
    ): R {
      return useMemo(() => {
        if (!state.loading && state.success && state.value) {
          return transformer(state.value);
        }
        return fallback;
      }, [
        state.attempt,
        state.loading,
        state.success,
        state.value,
        ...memoisations,
      ]);
    },
    unran: () => state.attempt === null,
    dispatch,
  });
}

/**
 * Use to trigger a new run
 *
 * @param promiseState
 * @param f
 * @returns
 */
export function withPromiseState<T>(
  promiseState: PromiseState<T>,
  f: Promise<T> | (() => Promise<T>),
): Promise<T> {
  return _withPromiseState(promiseState, f, Symbol());
}

// Wrap any Promise returning function in a PromiseState
// WARNING: rejections need to be handled outside of the with
function _withPromiseState<T>(
  promiseState: PromiseState<T>,
  f: Promise<T> | (() => Promise<T>),
  attempt: symbol,
): Promise<T> {
  promiseState.dispatch({ attempt, type: 'run' });
  try {
    const promise = f instanceof Promise ? f : f();
    promise.then(
      (result) => {
        promiseState.dispatch({
          type: 'success',
          value: result,
          attempt,
        });
      },
      (error) => {
        promiseState.dispatch({
          type: 'error',
          error: asError(error),
          attempt,
        });
      },
    );
    return promise;
  } catch (error) {
    promiseState.dispatch({
      type: 'error',
      error: asError(error),
      attempt,
    });
    throw error;
  }
}

export function usePromiseFunction<T>(
  promise: () => Promise<T>,
  memoisations: unknown[] = [],
): PromiseState<T> {
  const state = usePromiseState<T>(memoisations);

  useEffect(() => {
    if (state.loading) {
      _withPromiseState(state, promise, state.attempt ?? Symbol());
    }
  }, [state.attempt, state.loading]);

  return state;
}
