import React from 'react';
import type { PropsWithChildren } from 'react';
import { usePromiseFunction } from './usePromise';
import { internalFetch } from './internalFetch';

export function FetchButton({
  params,
  children,
}: PropsWithChildren<{ params: Parameters<typeof internalFetch>[0] }>) {
  const state = usePromiseFunction(() => internalFetch(params), [params]);
  return (
    <button disabled={state.loading} onClick={state.run}>
      {state.loading ? 'Loading' : children}
    </button>
  );
}
