import { User as AuthUser } from '../services/Auth';
import { SyntheticEvent, useEffect, useMemo } from 'react';
import { usePromiseFunction } from './usePromise';
import { internalFetch } from './internalFetch';

export type User = {
  login: (e?: SyntheticEvent) => void;
  logout: (e?: SyntheticEvent) => void;
  loading: boolean;
  data: AuthUser | null;
};

export const DefaultUser: User = {
  login: () => {},
  logout: () => {},
  loading: false,
  data: null,
};

/**
 * Provides a hook for user, performing a login
 */
export function useUser(): User {
  const fetchUser = usePromiseFunction<AuthUser>(() =>
    internalFetch({ url: '/user' }),
  );
  useEffect(fetchUser.run, []);
  const user = useMemo(() => {
    const user: User = {
      /**
       * Change page to confront user with a login dialog
       */
      login(e) {
        e?.preventDefault();
        window.location.href = '/auth/google';
      },
      /**
       * Call the logout endpoint then refetch user
       */
      logout(e) {
        fetch(`/logout`).then(() => {
          fetchUser.run();
        });
        e?.preventDefault();
      },
      loading: fetchUser.loading,
      data: fetchUser.value,
    };
    return user;
  }, [
    fetchUser.attempt,
    fetchUser.error,
    fetchUser.loaded,
    fetchUser.loading,
    fetchUser.success,
    fetchUser.value,
  ]);

  return user;
}
