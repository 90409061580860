import React, { ReactElement } from 'react';
import { CTA, CenterHV, LoadingRipple, Button, PromiseState } from '.';

export function PromiseChild<R, V>({
  data,
  promiseState,
  result,
  loading,
  errors,
  noData,
  unran,
  child,
  partialData,
}: {
  promiseState: PromiseState<R> | PromiseState<R>;
  result: V | undefined;
  data: null | V;
  loading: (s: PromiseState<R> | PromiseState<R>) => ReactElement;
  errors: (s: PromiseState<R> | PromiseState<R>) => ReactElement;
  noData: () => ReactElement;
  unran?: () => ReactElement;
  child: (data: V) => ReactElement;
  partialData?: boolean;
}) {
  if (promiseState.unran() && unran) {
    return unran();
  } else if (!promiseState.loaded) {
    // Check if partial data is available
    return partialData && result !== undefined ? (
      <>
        {child(result as V)}
        {loading(promiseState)}
      </>
    ) : (
      loading(promiseState)
    );
  } else if (!promiseState.success) {
    return errors(promiseState);
  } else if (result && data === null) {
    return noData();
  } else {
    return child(data as V);
  }
}

export function PromiseCard<R>(props: {
  promiseState: PromiseState<R> | PromiseState<R>;
  child: (d: R) => ReactElement;
  unran?: () => ReactElement;
  partialData?: boolean;
  result?: R;
}) {
  const { promiseState, child, unran, result, partialData } = props;
  return (
    <PromiseChild
      promiseState={promiseState}
      unran={unran}
      child={child}
      result={result}
      partialData={partialData}
      data={promiseState.value}
      loading={(_state) => {
        return _state.loading ? (
          <CenterHV>
            <CTA headline="Loading..." text="">
              <LoadingRipple />
            </CTA>
          </CenterHV>
        ) : (
          <div></div>
        );
      }}
      errors={(state) => {
        console.error('Got an error in PromiseCard', { error: state.error });
        return (
          <CenterHV>
            <CTA
              headline="Oh no - Errors!"
              text="We encountered some errors while fetching data"
            >
              <details>
                <summary>Error Details:</summary>
                <pre>
                  <code>
                    {promiseState.error?.message || '< missing error message >'}
                  </code>
                </pre>
                <pre>
                  <code>
                    {promiseState.error?.stack || '< missing error data >'}
                  </code>
                </pre>
                <pre>
                  <code>
                    {
                      // @ts-ignore
                      JSON.stringify(promiseState.error?.meta, null, 2)
                    }
                  </code>
                </pre>
              </details>
              <Button onClick={() => window.location.reload()} text="Reload" />
            </CTA>
          </CenterHV>
        );
      }}
      noData={() => (
        <CenterHV>
          <CTA
            headline="No Data"
            text="no data was returned - this is probably a bug"
          >
            <Button onClick={() => window.location.reload()} text="Reload" />
          </CTA>
        </CenterHV>
      )}
    />
  );
}
