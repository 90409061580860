import React, { useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
  NiceTable,
  usePromiseFunction,
  calcV1,
  PromiseCard,
  Checkbox,
  CopyButton,
} from './';
import { CalculationOutput } from '../Calculator';
import type CustomersCalc from '../calculations/customers.calc';
import {
  refineString,
  refineArrayOf,
  RefinementError,
} from '@normed/refinements';

type OrganisationInfo = CalculationOutput<typeof CustomersCalc>[number];
interface OrganisationInfoTableComponentProps {
  dataOverride?: () => Promise<OrganisationInfo[]>;
}

export const OrganisationInfoTableComponent: React.FC<
  OrganisationInfoTableComponentProps
> = ({ dataOverride }) => {
  const [enterpriseOnly, setEnterpriseOnly] = useState(true);

  const apiResult_customers = usePromiseFunction(() => {
    if (dataOverride) {
      return dataOverride();
    }
    return calcV1<typeof CustomersCalc>({
      calculationUrl: 'customers',
      body: {},
    });
  });

  React.useEffect(apiResult_customers.run, []);

  const validateAndRenderString = ({
    getValue,
  }: {
    getValue: () => unknown;
  }) => {
    const value = getValue();
    const refinedValue = refineString([], value);
    if (refinedValue instanceof RefinementError) {
      return 'Invalid string';
    }
    return refinedValue;
  };

  const validateAndRenderApiKeys = ({
    getValue,
  }: {
    getValue: () => unknown;
  }) => {
    const value = getValue();
    const refinedValue = refineArrayOf(refineString)([], value);
    if (refinedValue instanceof RefinementError) {
      return 'Invalid keys';
    }
    return (
      <div className="space-y-2">
        {refinedValue.map((key, index) => (
          <div key={index} className="flex items-center space-x-2">
            <span className="font-mono">{key}</span>
            <CopyButton value={key} />
          </div>
        ))}
      </div>
    );
  };

  const columns: ColumnDef<OrganisationInfo>[] = useMemo(
    () => [
      {
        header: 'Organisation Name',
        accessorKey: 'name',
        cell: validateAndRenderString,
      },
      {
        header: 'API Key',
        accessorKey: 'keys',
        cell: validateAndRenderApiKeys,
      },
      {
        header: 'Tier',
        accessorKey: 'tier',
        cell: validateAndRenderString,
      },
    ],
    [],
  );

  return (
    <PromiseCard
      promiseState={apiResult_customers}
      child={(data) => {
        const filteredData = enterpriseOnly
          ? data.filter((org) => org.tier === 'Enterprise')
          : data;

        return (
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <Checkbox
                checked={enterpriseOnly}
                id="enterpriseOnly"
                onCheckedChange={(checked) =>
                  setEnterpriseOnly(checked === true)
                }
                name="Enterprise Only"
              />
              <label
                htmlFor="enterpriseOnly"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Enterprise Only
              </label>
            </div>
            <NiceTable
              data={filteredData}
              columns={columns}
              initialSort={[{ id: 'name', desc: false }]}
            />
          </div>
        );
      }}
    />
  );
};
