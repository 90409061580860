import {
  canRefine,
  isRefinementError,
  RefinementError,
  RefinementFunction,
  refineObject,
  refineObjectOf,
  refineString,
} from '@normed/refinements';

export type Nested<T> = { [k: string]: T | Nested<T> };
export type Flattened<T> = { [k: string]: T };

export function refineNested<T>(
  refineT: RefinementFunction<T>,
): RefinementFunction<Nested<T>> {
  const canRefineT = canRefine(refineT);
  const refineNested = (
    path: string[],
    v: unknown,
  ): RefinementError | Nested<T> => {
    const obj = refineObject(path, v);
    if (isRefinementError(obj)) {
      return obj;
    }

    const nested: Nested<T> = {};
    for (const [key, value] of Object.entries(obj)) {
      const nestedPath = path.concat(key);
      if (canRefineT(nestedPath, value)) {
        nested[key] = value;
      } else {
        const subNested = refineNested(nestedPath, value);
        if (isRefinementError(subNested)) {
          return canRefineT.getLastError(); // could also be `return subNested`
        }

        nested[key] = subNested;
      }
    }
    return nested;
  };
  return refineNested;
}

export function refineFlattened<T>(
  refineT: RefinementFunction<T>,
): RefinementFunction<Flattened<T>> {
  return refineObjectOf(refineString, refineT);
}

export type MapNested<T, R> = (
  isT: (v: T | Nested<T>) => v is T,
  transform: (v: T, n: Nested<T>, r: string[], o: Nested<T>, k: string) => R,
  nested: Nested<T>,
) => Nested<R>;
export type MapFlattened<T, R> = (
  isT: (v: T | Nested<T>) => v is T,
  transform: (
    v: T,
    n: Flattened<T>,
    r: string[],
    o: Flattened<T>,
    k: string,
  ) => R,
  nested: Flattened<T>,
) => Flattened<R>;
export function mapNested<T, R>(
  isT: (v: T | Nested<T>) => v is T,
  transform: (v: T, n: Nested<T>, r: string[], o: Nested<T>, k: string) => R,
  nested: Nested<T>,
): Nested<R> {
  const result: Nested<R> = {};

  type Node = {
    route: string[];
    base: Nested<T>;
    transformed: Nested<R>;
  };

  const remaining: Node[] = [{ route: [], base: nested, transformed: result }];
  let next: Node | undefined;
  while ((next = remaining.shift())) {
    const { route, base, transformed } = next;
    for (const [key, value] of Object.entries(base)) {
      if (isT(value)) {
        transformed[key] = transform(
          value,
          nested,
          route.concat(key),
          base,
          key,
        );
      } else {
        const nextTransformed: Nested<R> = {};
        transformed[key] = nextTransformed;
        remaining.push({
          route: route.concat(key),
          base: value,
          transformed: nextTransformed,
        });
      }
    }
  }

  return result;
}
export function mapFlattened<T, R>(
  ...args: Parameters<MapFlattened<T, R>>
): Flattened<R> {
  // @ts-expect-error : Nested !== Flattened
  return mapNested(...args);
}

export function flattenNested<T>(
  isT: (v: T | Nested<T>) => v is T,
  nested: Nested<T>,
  sep: string = '/',
): Flattened<T> {
  const flattened: Flattened<T> = {};

  mapNested(
    isT,
    (v, _nested, route) => {
      flattened[route.join(sep)] = v;
    },
    nested,
  );

  return flattened;
}

export function nestFlattened<T>(
  isT: (v: T | Nested<T>) => v is T,
  flattened: Flattened<T>,
  sep: string = '/',
): Nested<T> {
  const nested: Nested<T> = {};

  const keys = Object.keys(flattened);
  for (const key of keys) {
    const route = key.split(sep);
    if (!route.length) {
      continue;
    }
    const parentRoute = route.slice(0, -1);
    const name = route.slice(-1)[0];

    let obj: Nested<T> = nested;
    for (const parent of parentRoute) {
      let parentObj = obj[parent];
      if (obj[parent] === undefined) {
        parentObj = {};
        obj[parent] = parentObj;
      } else if (isT(parentObj)) {
        throw new Error(`Conflicting objects ${key}`);
      }

      obj = parentObj;
    }

    if (obj[name]) {
      throw new Error(`Conflicting objects ${key}`);
    } else {
      obj[name] = flattened[key];
    }
  }

  return nested;
}
