import React, { useCallback, useId, useState } from 'react';
import Papa from 'papaparse';
import { Button } from './Button';

interface FileUploadProps {
  onDataParsed: (entityIds: string[]) => void;
  entityType: 'apps' | 'podcasts' | 'websites';
}

export const FileUpload = ({ onDataParsed, entityType }: FileUploadProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [parsedCount, setParsedCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const uniqueId = useId();
  const fileInputId = `file-upload-${uniqueId}`;

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      if (!file.name.endsWith('.csv')) {
        setError('Please upload a CSV file');
        return;
      }

      setSelectedFile(file);

      Papa.parse(file, {
        header: true,
        delimiter: ',',
        skipEmptyLines: true,
        complete: (results: Papa.ParseResult<string[]>) => {
          if (results.errors.length > 0) {
            setError('Error parsing CSV file');
            return;
          }
          const data = results.data as string[][];
          // Get first column values, excluding empty strings
          const appIds: string[] = data
            .map((row) => Object.values(row)[0])
            .filter((id: string) => (id && id.trim() !== '' ? id : ''));

          setParsedCount(appIds.length);
          onDataParsed(appIds);
        },
        error: (error: Error) => {
          setError(`Error reading file: ${error.message}`);
        },
      });
    },
    [onDataParsed],
  );

  const handleRemoveFile = useCallback(() => {
    setSelectedFile(null);
    setParsedCount(0);
    setError(null);
    onDataParsed([]);
  }, [onDataParsed]);

  return (
    <div className="space-y-4">
      <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
        {!selectedFile ? (
          <div>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
              id={fileInputId}
            />
            <label
              htmlFor={fileInputId}
              className="cursor-pointer text-blue-600 hover:text-blue-800"
            >
              Select a CSV File
            </label>
          </div>
        ) : (
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-500">{selectedFile.name}</span>
              <Button onClick={handleRemoveFile} variant="ghost" size="sm">
                Remove
              </Button>
            </div>
            <div className="text-sm">
              {parsedCount > 0 && `${parsedCount} ${entityType}`}
            </div>
          </div>
        )}
      </div>

      {error && <div className="text-sm text-red-600">{error}</div>}
    </div>
  );
};
