import { buttonVariants } from './Button';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import {
  DateRangeSelector,
  DateRangeSelector_DefaultValue,
  DateRangeSelector_Value,
  resolveDefaultValue,
} from './DateRangeSelector';

type InjectedValueType = string | number | DateTime | boolean;

const encode = (v: InjectedValueType): string => {
  if (v instanceof DateTime) return encodeURIComponent(v.toMillis());
  return encodeURIComponent(v);
};

export function GrafanaEmbed(options: {
  width?: number | string;
  height?: number | string;
  variables?: Record<string, string[] | InjectedValueType | undefined>;
  parameters?: Record<string, InjectedValueType | undefined>;
  link: string;
  displayIFrame?: boolean;
  displayPeriodSelector?: boolean;
  defaultPeriod?: DateRangeSelector_DefaultValue;
}) {
  const { width, height, link, displayIFrame } = Object.assign(
    {
      width: '100%',
      height: '50em',
      displayIFrame: true,
    },
    options,
  );

  const [selectedPeriod, setSelectedPeriod] = useState<DateRangeSelector_Value>(
    resolveDefaultValue(options.defaultPeriod),
  );
  const PeriodSelector = useMemo(() => {
    return () => {
      if (options.displayPeriodSelector !== false) {
        return (
          <div>
            <DateRangeSelector
              onChange={(dates) =>
                setTimeout(() => setSelectedPeriod(dates), 0)
              }
              defaultValue={options.defaultPeriod}
            />
          </div>
        );
      }
      return <></>;
    };
  }, [options.displayPeriodSelector]);

  const dashboardLink = new URL(link);
  const params = dashboardLink.searchParams;

  if (selectedPeriod.from) {
    params.append('from', encode(selectedPeriod.from));
  }

  if (selectedPeriod.to) {
    const toInclusive = selectedPeriod.to.endOf('day');
    if (toInclusive < DateTime.utc()) {
      // Only apply if the date is in the past, otherwise default to now
      params.append('to', encode(toInclusive));
    }
  }

  Object.entries(options.variables ?? {}).forEach(([k, v]) => {
    if (v === undefined) {
      return;
    }

    const valueArray = Array.isArray(v) ? v : [v];
    valueArray.forEach((opt) => {
      params.append('var-' + k, encode(opt));
    });
  });

  Object.entries(options.parameters ?? {}).forEach(([k, v]) => {
    if (v === undefined) {
      return;
    }
    params.append(k, encode(v));
  });

  dashboardLink.search = params.toString();

  const kioskParams = new URLSearchParams(params);
  kioskParams.append('kiosk', 'true');
  const kioskLink = new URL(dashboardLink.toString());
  kioskLink.search = kioskParams.toString();

  return (
    <div className="GrafanaEmbed__Container">
      <div className="GrafanaEmbed__info mb-1 px-4">
        <a
          className={buttonVariants({ variant: 'link' })}
          href={dashboardLink.toString()}
          target="_blank"
        >
          Link To Dashboard
        </a>
        <PeriodSelector />
      </div>
      {displayIFrame && (
        <iframe
          style={{ width, height }}
          src={kioskLink.toString()}
          className="GrafanaEmbed__iframe"
        />
      )}
    </div>
  );
}
