import {
  refineStringLiteral,
  refineAny,
  RefinementFunctionType,
} from '@normed/refinements';

import { DateTime, DateTimeFormatOptions } from 'luxon';

export const periods = ['1 day', '1 week', '1 month', '2 months'] as const;

export const refinePeriod = refineAny(...periods.map(refineStringLiteral));
export type Period = RefinementFunctionType<typeof refinePeriod>;

export function getLengthDays(period: Period) {
  switch (period) {
    case '1 day':
      return 1;
    case '1 month':
      return 30;
    case '2 months':
      return 60;
    case '1 week':
      return 7;
  }
}

// Returns the difference in days between two ISO8601 dates
export function getDiffDays(
  from?: string | DateTime,
  to?: string | DateTime,
): number {
  const fromString = coerceDateToString(from);
  const toString = coerceDateToString(to);

  if (!fromString || !toString) {
    return 0;
  }

  const fromDate = new Date(fromString);
  const toDate = new Date(toString);

  const diffTime = Math.abs(toDate.getTime() - fromDate.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function coerceDateToString(
  d: DateTime | string | undefined,
): string | undefined {
  if (d instanceof DateTime) {
    if (d.isValid) {
      return d.toISO();
    }
    return undefined;
  }
  return d;
}

// Returns a period (e.g. 1 day, 1 month) from two ISO8601 dates
export function getPeriodFromRange(
  from?: string | DateTime,
  to?: string | DateTime,
  defaultPeriod: Period = '1 day',
): Period {
  const days = getDiffDays(from, to);
  if (days == 0) {
    return defaultPeriod;
  } else if (days == 1) {
    return '1 day';
  } else if (days <= 7) {
    return '1 week';
  } else {
    return '1 month';
  }
}

export function getFormattedRangeOfPeriod({
  date,
  period,
}: {
  date: DateTime;
  period: Period;
}) {
  const options: DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  return `${date.minus({ days: getLengthDays(period) }).toLocaleString(options)} - ${date.toLocaleString(options)}`;
}
