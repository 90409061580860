// src/components/Button.tsx

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import classNames from 'classnames';
import { mergeCSSClasses } from './shadcnUtils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-uicore-primary text-white hover:bg-uicore-primary-light',
        outline:
          'border border-uicore-primary bg-background hover:bg-uicore-primary-light',
        secondary:
          'bg-uicore-secondary text-white hover:bg-uicore-secondary/80',
        ghost: 'hover:bg-uicore-primary-light',
        link: 'text-uicore-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  fallback?: string;
  loading?: boolean;
  download?: {
    title: string;
    getter: () => Blob;
  };
  text?: string;
  background?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  className,
  variant,
  size,
  asChild = false,
  fallback,
  loading,
  download,
  onClick,
  text,
  background,
  ...props
}) => {
  const Comp = asChild ? Slot : 'button';

  const buttonClass = classNames(
    mergeCSSClasses(buttonVariants({ variant, size, className })),
    {
      'bg-primary-light': background,
      'cursor-wait': loading,
    },
  );

  if (download) {
    return (
      <a
        className={buttonClass}
        download={download.title}
        target="_blank"
        rel="noreferrer"
        href={URL.createObjectURL(download.getter())}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {text || props.children}
      </a>
    );
  }

  return (
    <form
      action={fallback}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (onClick) onClick();
      }}
      className={classNames({ 'cursor-wait': loading })}
    >
      <Comp
        className={buttonClass}
        disabled={props.disabled || loading}
        {...props}
      >
        {text || props.children}
      </Comp>
    </form>
  );
};

export { Button, buttonVariants };
