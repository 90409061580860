import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';

export function PlotStackedBar<
  D extends { [k: string]: D[string] },
  SeriesKey extends keyof D,
  XKey extends keyof D,
  YKey extends keyof D,
  XValue extends number | string,
  YValue extends number | string,
>({
  rows,
  xKey,
  yKey,
  seriesKey,
  sort,
  transformX,
  transformY,
  logPlot,
  filter,
}: {
  rows: D[];
  xKey: XKey;
  transformX: (v: D[XKey], r: D) => XValue;
  yKey: YKey;
  transformY: (v: D[YKey], r: D) => YValue;
  seriesKey: SeriesKey;
  sort: (
    a: { x: XValue; y: YValue; row: D },
    b: { x: XValue; y: YValue; row: D },
  ) => number;
  logPlot?: boolean;
  filter?: (r: D) => boolean;
}) {
  type Series = {
    x: XValue[];
    y: YValue[];
    name: string;
    type: 'bar';
  };
  type Data = {
    rows: { row: D; x: XValue; y: YValue }[];
    name: string;
  };

  const { plotData, plotLayout } = useMemo(() => {
    // Group the data into the series'
    const dataBySeries: Map<string, Data> = new Map();
    for (const row of rows) {
      if (filter && !filter(row)) {
        continue;
      }
      const seriesName = String(row[seriesKey]);
      let data = dataBySeries.get(seriesName);
      if (!data) {
        data = {
          rows: [],
          name: seriesName,
        };
        dataBySeries.set(seriesName, data);
      }
      data.rows.push({
        row,
        x: transformX(row[xKey], row),
        y: transformY(row[yKey], row),
      });
    }

    // Sort the data by the x key
    for (const data of dataBySeries.values()) {
      data.rows.sort(sort);
    }

    // Turn it into a data row for Plotly
    const plotData: Series[] = [...dataBySeries.values()].map((data) => {
      const { x, y } = data.rows.reduce(
        (acc, { x, y }) => {
          acc.x.push(x);
          acc.y.push(y);
          return acc;
        },
        { x: [], y: [] } as { x: XValue[]; y: YValue[] },
      );
      return {
        x,
        y,
        name: data.name,
        type: 'bar',
      };
    });

    const plotLayout = {
      barmode: 'stack' as const,
      yaxis: {
        type: logPlot ? ('log' as const) : undefined,
      },
    };

    return { plotData, plotLayout };
  }, [rows, xKey, transformX, yKey, transformY, seriesKey, sort]);

  return (
    <Plot data={plotData} layout={plotLayout} config={{ responsive: true }} />
  );
}
