/**
 * A CTA
 */

import React, { PropsWithChildren } from 'react';

export function CTA({
  headline,
  text,
  children,
}: PropsWithChildren<{ headline?: string; text?: string }>) {
  return (
    <div className="p-4 border-4 border-transparent border-gradient-to-r from-uicore-primary to-uicore-secondary flex flex-col items-center justify-center">
      {headline ? (
        <h2 className="text-uicore-headline m-0 p-0">{headline}</h2>
      ) : null}
      {text ? <p className="text-uicore-body">{text}</p> : null}
      {children}
    </div>
  );
}
