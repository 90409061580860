import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { mergeCSSClasses } from './shadcnUtils';

// Copied from: https://ui.shadcn.com/docs/components/checkbox#installation
const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={mergeCSSClasses(
      'peer h-4 w-4 shrink-0 rounded-sm border border-broccoli-primary-500 ring-offset-uicore-light focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-uicore-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-broccoli-primary-500 data-[state=checked]:text-broccoli-neutral-200',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={mergeCSSClasses(
        'flex items-center justify-center text-current',
      )}
    >
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
