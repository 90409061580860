import { useMemo, useRef } from 'react';

/**
 * Run number, starting at 1
 *
 * Each time the memoisations chage the number resets to 1
 * @returns
 */
export function useRunNumber(memoisations: any[]): number {
  const runRef = useRef<number>(0);
  runRef.current++;
  const run = runRef.current;

  const offset = useMemo(() => run - 1, memoisations);
  return run - offset;
}
