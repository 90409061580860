import { isRefinementError, RefinementFunction } from '@normed/refinements';
import { useEffect, useMemo, useState } from 'react';

function parseQuery() {
  const queryString = window.location.search;
  const query: { [k: string]: string } = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString)
    .split('&')
    .filter((i) => !!i);
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}
function setQuery(o: { [k: string]: string }) {
  const entries = Object.entries(o);
  let query: string = '';
  if (entries.length) {
    query = entries
      .sort((a, b) => b[0].localeCompare(a[0]))
      .map(([k, v]) => {
        return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
      })
      .join('&');
  }
  const { protocol, host, pathname } = window.location;
  const url = `${protocol}//${host}${pathname}${query ? '?' : ''}${query}`;
  window.history.pushState({ path: url }, '', url);
}

function updateQueryString(prefix: string, value?: string): void {
  const values = parseQuery();
  if (value === undefined) {
    delete values[prefix];
  } else {
    values[prefix] = value;
  }
  setQuery(values);
}

function getQueryString(prefix: string): undefined | string {
  const values = parseQuery();
  const value = values[prefix];
  if (value && typeof value === 'string') {
    return value;
  }
  return undefined;
}

/**
 * useState where the value is stored in the query string, and the initial value is taken from it.
 *  Does not react to changes to the query string.
 *
 * Manages a single value, e.g. "?myval=3", as a string:
 * ```ts
 * const [ v, setV ] = useQueryValue("myval");
 * console.log(v); // 3
 * console.log(typeof v); // string
 * ```
 */
export function useQueryValue(
  key: string,
): [
  string | undefined,
  (v: (v: string | undefined) => string | undefined) => void,
] {
  const intitialValue: string | undefined = useMemo(
    () => getQueryString(key),
    [key],
  );
  const [value, setValue] = useState<string | undefined>(intitialValue);
  useEffect(() => {
    updateQueryString(key, value);
  }, [value]);
  return [value, setValue];
}

/**
 * useState where the value is stored in the query string, and the initial value is taken from it.
 *  Does not react to changes to the query string.
 *
 * Manages a single value, e.g. ?myval={"x":2} as a JSON object
 * ```ts
 * const [ v, setV ] = useQueryState("myval");
 * console.log(v); // {"x": 2}
 * console.log(typeof v); // object
 * ```
 */
export function useQueryState<T extends JSONishMember = undefined>(
  prefix: string,
  refine: RefinementFunction<T>,
): [T | undefined, (v: (s: T | undefined) => T | undefined) => void] {
  // Get initial state
  const initialState: T | undefined = useMemo(() => {
    const q = getQueryString(prefix);
    const v = refine([], q ? JSON.parse(q) : undefined);
    if (isRefinementError(v)) {
      return undefined;
    } else {
      return v;
    }
  }, [prefix]);

  const [state, setState] = useState<T | undefined>(initialState);
  useEffect(() => {
    updateQueryString(
      prefix,
      state === undefined ? state : JSON.stringify(state),
    );
  }, [prefix, state]);

  return [state, setState];
}
