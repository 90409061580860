import React, { useMemo } from 'react';

export function LookerStudioEmbed({
  id,
  page,
  height,
  width,
}: {
  id: null | string;
  page: null | string;
  height: number;
  width: number;
}) {
  const src = useMemo(() => {
    if (!id) {
      return null;
    }
    if (!page) {
      return `https://lookerstudio.google.com/embed/reporting/${id}`;
    }
    return `https://lookerstudio.google.com/embed/reporting/${id}/page/${page}`;
  }, [id, page]);

  if (!src) {
    // TODO: make not bad
    return (
      <div>
        <h3>No source provided</h3>
      </div>
    );
  }

  return (
    <iframe
      src={src}
      width={width}
      height={height}
      frameBorder="0" // Was present in GCP's provided code - likely not required
      style={{ border: 0 }}
      allowFullScreen={true}
    />
  );
}
