import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

// Get a hold of types they failed to export
import { ReactElement, RefAttributes } from 'react';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import type { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import BaseSelect from 'react-select/dist/declarations/src/Select';

type StateManagedSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = (
  props: StateManagerProps<Option, IsMulti, Group> &
    RefAttributes<BaseSelect<Option, IsMulti, Group>>,
) => ReactElement;

type Option<T> = {
  value: T;
  label: T;
};

export function useSelect<T>(label: string, values: T[], initialSelected?: T) {
  const defaultSelected = initialSelected || values[0];
  const [selected, setSelected] = useState<T>(defaultSelected);
  useEffect(() => {
    if (initialSelected) {
      setSelected(initialSelected);
    }
  }, [initialSelected]);

  const options = useMemo(() => {
    return values.map((v) => ({ value: v, label: v }));
  }, [values]);
  const SelectT = useMemo(() => {
    return (o: Parameters<StateManagedSelect<Option<T>>>[0]) => {
      return (
        <div>
          <label>{label}</label>
          <Select
            isMulti={false}
            defaultValue={{ label: defaultSelected, value: defaultSelected }}
            onChange={(e) => {
              setSelected(e?.value ?? defaultSelected);
            }}
            options={options}
            {...o}
          />
        </div>
      );
    };
  }, [options, label, initialSelected]);
  return {
    selected,
    SelectT,
  };
}

export function useMultiSelect<T>(label: string, values: T[]) {
  const [selected, setSelected] = useState<T[]>([]);
  const options = useMemo(() => {
    return values.map((v) => ({ value: v, label: v }));
  }, values);
  const SelectT = useMemo(() => {
    return (o: Parameters<StateManagedSelect<Option<T>, true>>[0]) => {
      return (
        <div>
          <label>{label}</label>
          <Select
            isMulti={true}
            onChange={(e) => {
              setSelected(Array.from(e?.values()).map((v) => v.value) ?? []);
            }}
            options={options}
            {...o}
          />
        </div>
      );
    };
  }, [options, label]);
  return {
    selected,
    SelectT,
  };
}
