import React, { ErrorInfo, ReactNode } from 'react';

export class ErrorBoundary extends React.Component<
  { children?: ReactNode },
  { hasError: boolean }
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <button
            onClick={() =>
              this.setState((state) => ({ ...state, hasError: false }))
            }
          >
            Retry
          </button>
        </>
      );
    }
    return this.props.children;
  }
}
