import { useRef } from 'react';

/**
 * Debounce a user input (with the specified ms debounce)
 *
 * This prevents updates from being triggered too rapidly. Only the last
 * call will be used.
 */
export function useAsyncDebounce<A extends any[]>(
  f: (...args: A) => void,
  ms: number,
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  return (...args: A): void => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => f(...args), ms);
  };
}
