import React from 'react';
import { createRoot } from 'react-dom/client';
import { User } from '../services/Auth';
import {
  LoginCTA,
  Banner,
  CenterHV,
  MainArea,
  AppContextProvider,
  UserContext,
  ErrorBoundary,
} from '../react-components';
import { useUser } from '../react-components/useUser';

function Page({
  title,
  Content,
}: {
  title?: string;
  Content: React.ComponentType<{ user: User }>;
}) {
  const user = useUser();
  return (
    <AppContextProvider user={user}>
      <Banner title={title} />
      <MainArea>
        <UserContext.Consumer>
          {(user) => {
            if (user?.data) {
              return (
                <ErrorBoundary>
                  <Content user={user.data} />
                </ErrorBoundary>
              );
            } else {
              return (
                <CenterHV>
                  <LoginCTA />
                </CenterHV>
              );
            }
          }}
        </UserContext.Consumer>
      </MainArea>
    </AppContextProvider>
  );
}

export function BasicPage(content: React.ComponentType<any>, title?: string) {
  document.addEventListener('DOMContentLoaded', function () {
    const root = document.querySelector('#react-root');
    if (!root) {
      throw new Error(`Missing root element`);
    }
    createRoot(root).render(<Page Content={content} title={title} />);
  });
}
