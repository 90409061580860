import React, { useState, useMemo } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Label } from '@radix-ui/react-label';
import { Circle } from 'lucide-react';
import { mergeCSSClasses } from './shadcnUtils';

type LimitedStringConvertible = string | number | boolean;
type RadioButtonOrientation = 'horizontal' | 'vertical';

interface RadioOption<T extends LimitedStringConvertible | number> {
  value: T;
  label: string;
}

// Overload
export function useRadioButton<T extends LimitedStringConvertible>(
  values: T[],
  defaultSelected: T,
  orientation?: RadioButtonOrientation,
): {
  selected: T;
  RadioButtonsT: React.FC;
};

export function useRadioButton<T extends LimitedStringConvertible>(
  values: RadioOption<T>[],
  defaultSelected: T,
  orientation?: RadioButtonOrientation,
): {
  selected: T;
  RadioButtonsT: React.FC;
};

// Implementation
export function useRadioButton<T extends LimitedStringConvertible>(
  values: T[] | RadioOption<T>[],
  defaultSelected: T,
  orientation: RadioButtonOrientation = 'horizontal',
) {
  const [selected, setSelected] = useState<T>(defaultSelected);

  const options: RadioOption<T>[] = useMemo(() => {
    if (values.length > 0 && typeof values[0] !== 'object') {
      return (values as T[]).map((value) => ({
        value,
        label: value.toString(),
      }));
    }
    return values as RadioOption<T>[];
  }, [values]);

  const RadioButtonsT: React.FC = useMemo(
    () => () => (
      <RadioGroupPrimitive.Root
        value={selected.toString()}
        onValueChange={(value) => setSelected(value as T)}
        className={`flex ${orientation === 'horizontal' ? 'flex-row space-x-4' : 'flex-col space-y-2'}`}
      >
        {options.map((option) => (
          <div
            className="flex items-center space-x-2"
            key={option.value.toString()}
          >
            <RadioGroupPrimitive.Item
              value={option.value.toString()}
              id={`radio-${option.value.toString()}`}
              className={mergeCSSClasses(
                'aspect-square h-4 w-4 rounded-full border border-uicore-contrast text-uicore-contrast shadow focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
              )}
            >
              <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
                <Circle className="h-2.5 w-2.5 fill-current text-current" />
              </RadioGroupPrimitive.Indicator>
            </RadioGroupPrimitive.Item>
            <Label htmlFor={`radio-${option.value.toString()}`}>
              {option.label}
            </Label>
          </div>
        ))}
      </RadioGroupPrimitive.Root>
    ),
    [options, selected, orientation],
  );

  return {
    selected,
    RadioButtonsT,
  };
}
