import React from 'react';
import { mergeCSSClasses } from './shadcnUtils';

export function LoadingRipple({
  size = 80,
  color = 'border-broccoli-secondary-500',
}: {
  size?: number;
  color?: string;
}) {
  const sizeStyle = { width: `${size}px`, height: `${size}px` };

  return (
    <div className={mergeCSSClasses('relative inline-block')} style={sizeStyle}>
      <div
        className={`absolute border-4 ${color} opacity-100 rounded-full animate-ripple`}
        style={sizeStyle}
      />
      <div
        className={`absolute border-4 ${color} opacity-100 rounded-full animate-ripple`}
        style={{ ...sizeStyle, animationDelay: '0.5s' }}
      />
    </div>
  );
}
