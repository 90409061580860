import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import { mergeCSSClasses } from './shadcnUtils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={mergeCSSClasses('border-b border-b-transparent', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex w-full">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={mergeCSSClasses(
        'flex flex-1 items-center justify-between py-4 font-medium transition-all hover:text-gray-900',
        'text-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-uicore-primary rounded-lg',
        '[&[data-state=open]>div>svg]:rotate-180',
        className,
      )}
      {...props}
    >
      <div className="flex-1">{children}</div>
      <div className="flex items-center justify-center w-8 h-8 rounded-full transition-transform duration-200 ease-out">
        <ChevronDown className="h-5 w-5 transition-transform duration-200 ease-out" />
      </div>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={mergeCSSClasses(
      'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className,
    )}
    {...props}
  >
    <div className="pt-1 pb-4">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export interface SectionProps extends PropsWithChildren {
  title: string | ReactElement;
  className?: string;
  collapsed?: boolean;
  onCollapse?: (collapsed: boolean) => void;
  anchor?: string;
  variant?: 'default' | 'outline' | 'ghost';
}

export function Section({
  title,
  collapsed: initialCollapsed,
  onCollapse,
  children,
  className,
  anchor,
  variant = 'default',
}: SectionProps) {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const variantStyles = {
    default: 'border-l-4 pl-4 border-uicore-primary bg-white shadow-sm',
    outline: 'border border-gray-200 bg-white',
    ghost: 'bg-transparent',
  };

  return (
    <ErrorBoundary>
      <Accordion
        type="single"
        collapsible
        className={mergeCSSClasses(
          'rounded-lg transition-all duration-200 ease-out',
          variantStyles[variant],
          'border-l-4 pl-4 my-4 border-broccoli-primary-500 rounded-md transition-all duration-300',
          className,
        )}
        value={collapsed ? '' : 'item-1'}
        onValueChange={(value) => {
          const isCollapsed = value !== 'item-1';
          setCollapsed(isCollapsed);
          onCollapse?.(isCollapsed);
        }}
      >
        <AccordionItem value="item-1">
          <AccordionTrigger>
            {anchor ? (
              <a
                href={`#${anchor}`}
                className="mr-2 text-gray-400 hover:text-gray-600"
                aria-label="Link to section"
              >
                #
              </a>
            ) : null}
            {title}
          </AccordionTrigger>
          <AccordionContent>{children}</AccordionContent>
        </AccordionItem>
      </Accordion>
    </ErrorBoundary>
  );
}
