import React, { useEffect, useState } from 'react';
import { ServerContext, calcV1, usePromiseFunction, withContext } from '.';
import type AppDataCalc from '../calculations/app_data.calc';
import Select from 'react-select';
import {
  MultiValueGenericProps,
  components as SelectComponents,
} from 'react-select';

/**
 * A collection of rows, containing many apps
 */
export interface AppDataGroup {
  readonly label: string;
  readonly options: AppDataRow[];
}
/**
 * A selectable 'row' that may have one or more apps associated with it
 */
export interface AppDataRow {
  readonly label: string;
  readonly type: 'org' | 'app';
  readonly value: AppData[];
}
/**
 * The raw data for a single app
 */
export interface AppData {
  readonly app_id: string;
  readonly app_name: string | null;
  readonly org_id: string;
  readonly org_name: string;
}

/**
 * An async function to fetch the data for many apps
 */
export async function app_id_selector_data(
  options?: ServerContext,
): Promise<AppDataGroup[]> {
  console.log(`Selecting`);
  return calcV1<typeof AppDataCalc>({
    calculationUrl: 'app_data',
    body: {},
    options,
  }).then((apps) => {
    console.log(`Fetched`, { apps });
    // Group the apps into orgs
    const orgMap: {
      [k: string]: { org_id: string; org_name: string; apps: typeof apps };
    } = {};
    for (const app of apps) {
      const org = (orgMap[app.org_id] = orgMap[app.org_id] ?? {
        org_id: app.org_id,
        org_name: app.org_name,
        apps: [],
      });
      org.apps.push(app);
    }
    // List of orgs with apps accessible through it
    const orgs = [...Object.values(orgMap)];

    // We are creating a structure of apps and groups
    // First a list of orgs
    const organisations: AppDataGroup = {
      label: 'Organisations',
      options: orgs.map(
        (org): AppDataRow => ({
          label: org.org_name,
          type: 'org',
          value: org.apps,
        }),
      ),
    };
    return [
      organisations,
      ...orgs.map(
        (org): AppDataGroup => ({
          label: 'Org: ' + org.org_name,
          options: org.apps.map(
            (app): AppDataRow => ({
              label: app.app_name ?? app.app_id,
              type: 'app',
              value: [app],
            }),
          ),
        }),
      ),
    ];
  });
}

function appEquality(a: AppData, b: AppData): boolean {
  return a.app_id === b.app_id;
}

export const AppIDLabel = (
  props: MultiValueGenericProps<AppDataRow, true, AppDataGroup>,
) => {
  const label =
    (props.data.type === 'org' ? 'Org: ' : 'App: ') + props.data.label;
  return (
    <SelectComponents.MultiValueLabel {...props}>
      {label}
    </SelectComponents.MultiValueLabel>
  );
};

/**
 * A multi select for many app ids
 */
const RawAppIDSelector = ({
  selectorOverride,
  onChange,
  host,
}: {
  selectorOverride?: () => Promise<AppDataGroup[]>;
  onChange: (
    v: {
      app_name: string | null;
      app_id: string;
      org_name: string;
      org_id: string;
    }[],
  ) => void;
} & ServerContext): React.JSX.Element => {
  const [value, setValue] = useState<AppDataRow[]>([]);
  const optionsPromiseState = usePromiseFunction(() => {
    if (selectorOverride) {
      return selectorOverride();
    } else {
      return app_id_selector_data({ host });
    }
  }, [host]);

  useEffect(() => {
    console.log(`Triggering run`);
    optionsPromiseState.run();
  }, [host]);

  return (
    <Select<AppDataRow, true, AppDataGroup>
      className={optionsPromiseState.error ? 'Select__Select--error' : ''}
      isLoading={optionsPromiseState.loading}
      isMulti
      isDisabled={Boolean(optionsPromiseState.error)}
      isSearchable
      hideSelectedOptions
      components={{ MultiValueLabel: AppIDLabel }}
      options={optionsPromiseState.value ?? []}
      backspaceRemovesValue
      onChange={(nestedRows) => {
        setValue(nestedRows.flat());
        onChange(
          nestedRows
            .flatMap((row) => row.value)
            .filter((v, i, a) => a.findIndex((r) => appEquality(v, r)) === i),
        );
        return nestedRows;
      }}
      value={value}
    />
  );
};

export const AppIDSelector = withContext(ServerContext)(RawAppIDSelector);
