/*
 * WARNING: This file is autogenerated by ../../autogen/bundles/bin/start.js
 */

export const pages = {
  Archived: {
    audiences: '/Archived/audiences.html',
    bqingest: '/Archived/bqingest.html',
    calcs: '/Archived/calcs.html',
    construction: {
      euclid_lite_health: {
        health: '/Archived/construction/euclid_lite_health/health.html',
      },
    },
    context: '/Archived/context.html',
    latency: '/Archived/latency.html',
    log: '/Archived/log.html',
    storybook: '/Archived/storybook.html',
  },
  Engineering: {
    API_Keys: '/Engineering/API_Keys.html',
    Cloud_Device_Audiences: '/Engineering/Cloud_Device_Audiences.html',
    Model_Runner: '/Engineering/Model_Runner.html',
    Sanity_Check: '/Engineering/Sanity_Check.html',
    Status_Logs: '/Engineering/Status_Logs.html',
  },
  Euclid_Lite: {
    Analysis: { index: '/Euclid_Lite/Analysis/index.html' },
    App_Category: { index: '/Euclid_Lite/App_Category/index.html' },
    App_Distributions: { index: '/Euclid_Lite/App_Distributions/index.html' },
    App_Report: { index: '/Euclid_Lite/App_Report/index.html' },
    Monitoring: { index: '/Euclid_Lite/Monitoring/index.html' },
    Podcast_Distributions: {
      index: '/Euclid_Lite/Podcast_Distributions/index.html',
    },
    Podcast_Impressions: {
      index: '/Euclid_Lite/Podcast_Impressions/index.html',
    },
    Podcast_Monitoring: { index: '/Euclid_Lite/Podcast_Monitoring/index.html' },
  },
  Euclid: {
    Monitoring: { index: '/Euclid/Monitoring/index.html' },
    Reporting: { index: '/Euclid/Reporting/index.html' },
  },
  index: '/index.html',
} as const;
