import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

export function magicHeader(s: string): string {
  let o = s;
  o = o.replace(/_/g, ' ');
  o = o.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
  return o;
}

export function useMagicColumns<V extends { [k: string]: V[keyof V] }>(
  v: V[],
): ColumnDef<V>[] {
  return useMemo(() => {
    if (v.length === 0) {
      return [];
    }

    const keys = Object.keys(v[0]) as (keyof V)[];
    return keys.map((key) => ({
      id: String(key),
      header: magicHeader(String(key)),
      accessorKey: String(key),
    }));
  }, [v]);
}
