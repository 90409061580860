import { useEffect, useMemo } from 'react';

/**
 * Controls the title on the page
 *
 * Does not update if the title updates independently of this code
 */
export function useTitle(
  title: string | (() => string),
  memoisations: any[],
): void {
  const t = useMemo(() => {
    if (typeof title === 'string') return title;
    return title();
  }, memoisations);

  useEffect(() => {
    document.title = t;
  }, [t]);
}
