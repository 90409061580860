import React from 'react';
import { mergeCSSClasses } from './shadcnUtils';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  action?: React.ReactNode;
  variant?: 'default' | 'outline';
}

export function Card({
  title,
  subtitle,
  action,
  children,
  className,
  variant = 'default',
  ...props
}: CardProps) {
  const variantStyles = {
    default: 'bg-white shadow-lg',
    outline: 'bg-white border border-gray-200',
  };

  return (
    <div
      className={mergeCSSClasses(
        'rounded-lg overflow-hidden',
        variantStyles[variant],
        className,
      )}
      {...props}
    >
      {(title || action) && (
        <div className="bg-gray-100 px-6 py-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div>
              {title && (
                <h3 className="font-bold text-xl text-gray-800">{title}</h3>
              )}
              {subtitle && (
                <p className="mt-1 text-sm text-gray-600">{subtitle}</p>
              )}
            </div>
            {action && <div>{action}</div>}
          </div>
        </div>
      )}
      <div className="px-6 py-4">{children}</div>
    </div>
  );
}
