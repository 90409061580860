import React from 'react';
import { UserContext } from '.';
import { LogIn, LogOut } from 'lucide-react';

const BroccoliLogo = ({
  className = '',
  size = 24,
}: {
  className?: string;
  size?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2000 1689.8"
    width={size}
    height={size * 0.845}
    className={className}
  >
    <path
      d="M1512.7,930.3c0,419.5-338.6,759.5-756.3,759.5S0,1349.8,0,930.3H1512.7z"
      fill="currentColor"
    />
    <path
      d="M487.3,759.5C487.3,340,825.9,0,1243.7,0S2000,340,2000,759.5H487.3z"
      fill="currentColor"
    />
  </svg>
);

export function Banner({ beta }: { title?: string; beta?: boolean }) {
  const headerText = beta ? 'Broccoli - BETA' : 'Broccoli';

  return (
    <header className="bg-broccoli-primary-600 w-full shadow-sm">
      <div className="container mx-auto px-4 py-4 flex items-center justify-between max-w-7xl">
        <div className="flex items-center">
          <h1 className="text-white m-0 text-xl font-semibold tracking-tight flex items-center">
            <a
              href="/"
              className="text-white no-underline hover:text-broccoli-primary-100 transition-colors flex items-center"
            >
              {/* Custom Logo */}
              <div className="w-8 h-8 bg-white bg-opacity-20 rounded-md flex items-center justify-center mr-3">
                <BroccoliLogo className="text-white" size={20} />
              </div>
              {headerText}
            </a>
          </h1>
        </div>

        <ProfileBox />
      </div>
    </header>
  );
}

function ProfileBox() {
  return (
    <UserContext.Consumer>
      {(user) => (
        <div className="flex items-center">
          {user.data ? (
            <>
              <div className="hidden md:flex items-center mr-4">
                <div className="w-8 h-8 rounded-full bg-white bg-opacity-20 flex items-center justify-center text-white">
                  {user.data.givenName?.charAt(0) || '?'}
                </div>
                <span className="ml-2 text-sm font-medium text-white">
                  {user.data.givenName || ''}
                </span>
              </div>
              <a
                href="/logout"
                onClick={user.logout}
                className="flex items-center text-white no-underline px-3 py-1.5 rounded bg-broccoli-primary-700 hover:bg-broccoli-primary-800 transition-colors"
              >
                <LogOut className="w-4 h-4 mr-1.5" />
                <span className="text-sm">Logout</span>
              </a>
            </>
          ) : (
            <a
              href="/auth/google"
              onClick={user.login}
              className="flex items-center text-white no-underline px-3 py-1.5 rounded bg-broccoli-primary-700 hover:bg-broccoli-primary-800 transition-colors"
            >
              <LogIn className="w-4 h-4 mr-1.5" />
              <span className="text-sm">Login</span>
            </a>
          )}
        </div>
      )}
    </UserContext.Consumer>
  );
}
