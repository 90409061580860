import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';

export function PlotSunburst({
  values,
  labels,
  parents,
  ids,
}: {
  values: number[];
  parents: string[];
  labels: string[];
  ids: string[];
}) {
  const data = useMemo(
    () => [
      {
        type: 'sunburst' as const,
        labels,
        values,
        parents,
        text: values.map(
          (value) =>
            `%{label}<br>${Math.ceil(value / 60)} minutes per user per day`,
        ),
        ids,
        branchvalues: 'total' as const,
        hovertemplate: `%{text}`,
      },
    ],
    [labels, values, parents, ids],
  );

  return <Plot data={data} layout={{}} config={{ responsive: true }} />;
}
