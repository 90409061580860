import { Query } from '@numbereight/udatabase';
import { DateTime, Interval } from 'luxon';
import { RefinementError } from '@normed/refinements';
import { Logger } from '@numbereight/logging';

export function getAppMap(
  logger: Logger,
  raw: {
    app_ids?: string[];
    groups?: { [group: string]: string[] };
  },
): [Map<string, string[]>, string[]] {
  const appMap = new Map<string, string[]>();
  for (const app of raw.app_ids ?? []) {
    appMap.set(app, [app]);
  }
  for (const [group, appIds] of Object.entries(raw.groups ?? {})) {
    if (appMap.has(group)) {
      logger.warn(`Conflicting group and app name`, { group, app: group });
    }
    appMap.set(group, appIds);
  }
  const uniqueAppIds = Array.from(new Set(Array.from(appMap.values()).flat()));
  return [appMap, uniqueAppIds];
}

export function refineDateTime(
  path: string[],
  v: unknown,
): DateTime | RefinementError {
  if (!(v instanceof DateTime)) {
    return new RefinementError(path, `should be a DateTime, but isn't.`);
  }
  if (!v.isValid) {
    return new RefinementError(
      path,
      `should be valid, but isn't: ${v.invalidReason}`,
    );
  }
  return v;
}

export const u = {
  json_access(q: Query, column: string, field: string): string {
    switch (q.type) {
      case 'postgres':
        return `${column}->${field}`;
      case 'snowflake':
        return `${column}:${field}`;
      case 'bigquery':
        return `JSON_VALUE(${q.help.quoteObject(column)}, ${q.help.quoteString(
          `$.${field}`,
        )})`;
    }
  },
  json_obj(
    q: Query,
    internal: { [k: string]: string } | [string, string][],
  ): string {
    const entries = Array.isArray(internal)
      ? internal
      : Object.entries(internal);
    switch (q.type) {
      case 'postgres':
        return `json_build_object(${entries.flat().join(',')})`;
      case 'snowflake':
        return `OBJECT_CONSTRUCT(${entries.flat().join(',')})`;
      case 'bigquery':
        // SELECT TO_JSON(STRUCT(1 AS id, [10,20] AS coordinates)) AS pt;
        // -> { id: 1, coordinates: [10, 20]}
        // (https://cloud.google.com/bigquery/docs/reference/standard-sql/json-data#convert_a_sql_type_to_json)
        return `TO_JSON(STRUCT(${entries
          .map(([k, v]) => `${v} AS ${k}`)
          .join(',')}))`;
    }
  },
  json_obj_agg(q: Query, k: string, v: string, over?: string): string {
    switch (q.type) {
      case 'bigquery':
        return `agg_json(ARRAY_AGG(STRUCT(CAST(${k} AS STRING), TO_JSON(STRUCT(${v} AS value)))) ${
          over ? `OVER (${over})` : ``
        })`;
      case 'snowflake':
        return `OBJECT_AGG(${k}, ${v})`;
      case 'postgres':
        throw new Error(`Unimplemented`);
    }
  },
  json_obj_agg_definition(): string {
    return `
      CREATE TEMP FUNCTION agg_json(input ARRAY<STRUCT<k STRING, v JSON>>)
      RETURNS JSON
      LANGUAGE js AS """
      if(!input || !input.length)
        return {};
      return Object.fromEntries(input.map(({k, v}) => [k, v.value]));
      """;
    `;
  },
  bool_or_agg(q: Query, internal: string) {
    switch (q.type) {
      case 'postgres':
        return `BOOL_OR(${internal})`;
      case 'snowflake':
        return `BOOLOR_AGG(${internal})`;
      case 'bigquery':
        return `LOGICAL_OR(${internal})`;
    }
  },
};

export const t = {
  getDayNumber(d: DateTime) {
    return Interval.fromDateTimes(DateTime.fromMillis(0), d).length('days');
  },
  getWeekNumber(d: DateTime) {
    return Interval.fromDateTimes(DateTime.fromMillis(0), d).length('weeks');
  },
};

export const range = {
  week(q: Query, from: DateTime | null, to: DateTime | null): string | null {
    const toWeek = to ? t.getWeekNumber(to) : null;
    const fromWeek = from ? t.getWeekNumber(from) : null;
    if (fromWeek && toWeek) {
      return `week_number BETWEEN ${q.value(fromWeek)} AND ${q.value(toWeek)}`;
    } else if (fromWeek) {
      return `week_number >= ${q.value(fromWeek)}`;
    } else if (toWeek) {
      return `week_number >= ${q.value(toWeek)}`;
    } else {
      return null;
    }
  },
  time(q: Query, from: DateTime | null, to: DateTime | null): string | null {
    const isoFrom = from ? from.toISO() : null;
    const isoTo = to ? to.toISO() : null;
    if (isoFrom && isoTo) {
      return `timestamp BETWEEN ${q.value(isoFrom)} AND ${q.value(isoTo)}`;
    } else if (isoFrom) {
      return `timestamp > ${q.value(isoFrom)}`;
    } else if (isoTo) {
      return `timestamp > ${q.value(isoTo)}`;
    } else {
      return null;
    }
  },
};

export function is_active(q: Query, to: DateTime | null) {
  const toDay = to ? t.getDayNumber(to) : t.getDayNumber(DateTime.now());
  return `${q.value(
    toDay,
  )} - MAX(day_number) OVER(PARTITION BY app_id, device_id) < 30`;
}

export const topics = [
  'activity',
  'device_position',
  'indoor_outdoor',
  'motion/device_movement',
  'place',
  'reachability',
  'situation',
  'time',
  'weather',
];
