import React, { useMemo, useState } from 'react';

// Simple text input.
export function useText(placeholder?: string) {
  const [currentValue, setValue] = useState<string>('');

  // In review, we thought we shouldn't use 'useMemo', however when removing it
  // and returning the '<input ...>' element directly, React recreates the textbox
  // on every keypress, causing the textbox to become unfocused (which is rather frustrating)
  const Text = useMemo(
    () => () => {
      return (
        <div>
          <input
            type="text"
            onChange={(e) => {
              setValue(e?.target.value);
            }}
            placeholder={placeholder}
          />
        </div>
      );
    },
    [placeholder],
  );

  return {
    value: currentValue,
    Text,
  };
}
