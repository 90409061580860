import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { usePromiseFunction } from './usePromise';
import { calcV1 } from './calcV1';
import { CalculationOutput } from '../Calculator';
import type CustomersCalc from '../calculations/customers.calc';
import { ServerContext, withContext } from './AppContext';
import { useRadioButton } from './useRadioButtons';

export type CustomerOption = CalculationOutput<typeof CustomersCalc>[number];

type BaseSelectParams = React.ComponentProps<
  typeof Select<CustomerOption, false>
>;
type SelectParams = Omit<
  BaseSelectParams,
  'value' | 'options' | 'onChange' | 'isMulti' | 'isLoading'
>;

const tiers = ['Free', 'Enterprise'] as const;
type Tier = (typeof tiers)[number];

export function CustomerSelectorSingle({
  tier,
  allowTierChange,
  selectorOverride,
  onChange,
  host,
  ...params
}: {
  tier?: Tier;
  allowTierChange?: boolean;
  selectorOverride?: () => Promise<CustomerOption[]>;
  onChange: (v: CustomerOption | null) => void;
} & ServerContext &
  SelectParams): React.JSX.Element {
  const { selected: tierState, RadioButtonsT: TierSelectorT } = useRadioButton<
    Tier | 'All Customers'
  >([...tiers, 'All Customers'], tier ?? 'All Customers', 'horizontal');

  const apiRequest = usePromiseFunction(() => {
    if (selectorOverride) {
      return selectorOverride();
    }

    return calcV1<typeof CustomersCalc>({
      calculationUrl: 'customers',
      body: {},
      options: {
        host,
      },
    });
  }, [host]);
  useEffect(apiRequest.run, [host]);

  const options = useMemo(() => {
    if (apiRequest.value === null) {
      return [];
    }
    if (tierState !== 'All Customers') {
      return apiRequest.value?.filter((option) => option.tier === tierState);
    }
    return apiRequest.value;
  }, [apiRequest.value, tierState]);

  const [value, setValue] = useState<CustomerOption | null>(null);

  return (
    <div className={params.className}>
      <Select<CustomerOption, false>
        isDisabled={Boolean(apiRequest.error)}
        isSearchable
        isClearable
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        placeholder="Select customer..."
        {...params}
        className={apiRequest.error ? 'Select__Select--error' : ''}
        isMulti={false}
        isLoading={apiRequest.loading}
        options={options}
        value={value}
        onChange={(v) => {
          setValue(v);
          onChange(v);
        }}
      />
      {allowTierChange !== false && (
        <div className="mt-2 flex space-x-4">
          <label>Filter by customer tier: </label>
          <TierSelectorT />
        </div>
      )}
    </div>
  );
}

export const CustomerSelector = withContext(ServerContext)(
  CustomerSelectorSingle,
);
