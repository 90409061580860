/**
 * Default broccoli web page
 * All other pages a user has permission to access should be reachable through this one
 */
import React from 'react';
import { User } from '../services/Auth';
import { BasicPage } from '../react-components';
import { pages } from '../html.autogen';

type Pages = { [k: string]: Pages | string };

function Node({ pages, user }: { pages: Pages; user: User }) {
  return (
    <ul className="ml-4 list-disc">
      {Object.keys(pages)
        .sort()
        .map((key) => {
          const v = pages[key];
          if (key === 'Archived' && !user.permissions.includes('archived')) {
            return null; // Don't render the Archived section
          }
          if (typeof v === 'string') {
            return (
              <li key={key} className="mt-1">
                <a href={v} className="text-blue-500 hover:underline">
                  {key === 'index' ? 'Home' : key.replace(/_/g, ' ')}
                </a>
              </li>
            );
          } else if (typeof v['index'] === 'string') {
            const withoutIndex = { ...v };
            delete withoutIndex['index'];
            return (
              <li key={key} className="mt-1">
                <a href={v['index']} className="text-blue-500 hover:underline">
                  {key.replace(/_/g, ' ')}
                </a>
                <Node pages={withoutIndex} user={user} />
              </li>
            );
          } else {
            return (
              <li key={key} className="mt-1">
                {key.replace(/_/g, ' ')}
                <Node pages={v} user={user} />
              </li>
            );
          }
        })}
    </ul>
  );
}

function HomePage({ user }: { user: User }) {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Welcome {user.givenName}</h1>
      <div className="mb-4">
        <p className="mb-2">Recent naming changes:</p>
        <ul className="list-disc ml-6">
          <li className="mt-1">Demo-split is now Analysis</li>
          <li className="mt-1">Usage is now Monitoring</li>
        </ul>
      </div>
      <Node pages={pages} user={user} />
    </div>
  );
}

BasicPage(HomePage, 'Broccoli');
